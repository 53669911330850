import errorLogger from './errorLogger';
import CollaterateInputErrorList from './CollaterateInputErrorList';
import CollaterateResponseError from './CollaterateResponseError';

const errorResponseHandler = e => {
    let error = e;

    if (e.response) {
        // received error response from server
        if (e.response.status === 400 && e.response.data.errors && e.response.data.errors[0].source) {
            error = new CollaterateInputErrorList(e.response.data);
        } else if (e.response.data.errors) {
            error = new CollaterateResponseError(e.response.data.errors[0]);
        }
    } else if (e.request) {
        // request was made, but no response received
    } else {
        // Something happened when setting up the request that triggered the error
        // e.message
    }
    // e.config always available
    // e.message always available

    errorLogger.log(e, error);

    return Promise.reject(error);
};

export default errorResponseHandler;
