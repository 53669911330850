/**
 * A site menu as defined within the Collaterate site admin
 *
 * @property {number} id - (Read only) This Menu's unique id.
 * @property {string} name - (Read only) The name of the menu.
 * @property {boolean} active - (Read only) Whether or not this menu is marked active.
 * @property {boolean} showThumbnails - (Read only) Whether or not this menu should show thumbnails.
 * @property {Menu~menuChild[]} children - (Read only) Any child menu items.
 */

class Menu {
    constructor (data) {
        this.id = data.id;
        this.name = data.name || '';
        this.active = Boolean(data.active);
        this.showThumbnails = Boolean(data.showThumbnails);
        this.children = Object.freeze(data.children.map(generateMenuChild))
        Object.freeze(this);
    }
}

export default Menu;

/**
 * Menu child object
 *
 * @typedef {Object} Menu~menuChild
 * @property {number} id - (Read only) The id for this menu child.
 * @property {string} label - (Read only) The label for this menu child.
 * @property {?string} href - (Read only) The link used by this menu child. Null if not applicable.
 * @property {boolean} external - (Read only) Whether or not this menu child links externally from the site.
 * @property {boolean} externalLinkNewWindow - (Read only) Whether or not this link has been specified to open in a new tab.
 * @property {Menu~menuChild[]} children - (Read only) Any child menu items.
 * @property {?string} thumbUrl - (Read only) The url to the thumbnail for this menu child. Null if not applicable.
 * @property {?number} thumbWidth - (Read only) The specified width for this menu child's thumbnail. Null if not applicable.
 * @property {?number} thumbHeight - (Read only) The specified height for this menu child's thumbnail. Null if not applicable.
 */

function generateMenuChild (menuChild) {
    return Object.freeze({
        id: menuChild.id,
        label: menuChild.label || '',
        href: menuChild.href || null,
        external: Boolean(menuChild.external),
        externalLinkNewWindow: Boolean(menuChild.externalLinkNewWindow),
        children: Object.freeze(menuChild.children.map(generateMenuChild)),
        thumbUrl: menuChild.thumbUrl || null,
        thumbWidth: Number(menuChild.thumbWidth) || null,
        thumbHeight: Number(menuChild.thumbHeight) || null,
    });
}
