import OrderItem from '../OrderItem/OrderItem';
import ShippingSummary from '../Shipping/ShippingSummary';
import User from '../User/User';
import { nullSafeToString, intToFormattedString } from '../lib/util';
import TruncatedDecimal from '../lib/TruncatedDecimal';

/**
 * A user project, containing configured items, pricing, promotion and shipping information.
 *
 * @property {number} id - (Read only) This Project's unique id.
 * @property {?number} quoteId - (Read only) This Project's custom quote id, if it is one.
 * @property {?string} externalQuoteId - (Read only) This Project's custom quote (v2) id, if it is one.
 * @property {string} name - (Read only unless self-serve) The name of the project.
 * @property {string} description - (Read only unless self-serve) The description for this project.
 * @property {?string} customerNotes (Read only) Notes by the customer on why requote was requested or why quote was declined.
 * @property {OrderItem[]} items - (Read only) The {@link OrderItem order items} the user has added to this Project.
 * @property {?ShippingSummary} shippingSummary - (Read only) Shipment details for this project. Null if not set.
 * @property {?Project~promotion} promotion - (Read only) The promotion applied to this project. Null if none set.
 * @property {?Project~userDiscount} userDiscount - (Read only) The user discount for this user / project. Null if not applicable.
 * @property {?Project~loyaltyDiscount} loyaltyDiscount - (Read only) The loyalty discount for this user / project. Null if not applicable.
 * @property {?string} status -(Read only) A user-friendly name for the current state the project quote is in. Null if not applicable.
 * @property {Date} expiresOn - (Read only) The date this project will expire.
 * @property {Date} updatedOn - (Read only) The date this project was last updated.
 * @property {Date} quotedOn - (Read only) The date this project was initially quoted.
 * @property {?User} quotedBy - (Read only) The user that created this project. Null if anonymous cart.
 * @property {number} subtotalPrice - (Read only) This project's subtotal - the total price of all items within, before handling, shipping, tax, credits, discounts and promotions.
 * @property {string} displaySubtotalPrice - (Read only) this.subtotalPrice as string, formatted with commas and two decimal places.
 * @property {number} handlingPrice - (Read only) The total price for handling on this project.
 * @property {string} displayHandlingPrice - (Read only) this.handlingPrice as string, formatted with commas and two decimal places.
 * @property {number} creditAccountsDiscountAmount - (Read only) Amount of price reductions due to credit accounts.
 * @property {string} displayCreditAccountsDiscountAmount - (Read only) this.creditAccountsDiscountAmount as string, formatted with commas and two decimal places.
 * @property {number} taxPrice - (Read only) The price of tax on this project. Typically displayed only if this.shipment is set.
 * @property {string} displayTaxPrice - (Read only) this.taxPrice as string, formatted with commas and two decimal places.
 * @property {number} totalPrice - (Read only) The final price on this project, after shipping, handling, tax, credits, promotions and discounts.
 * @property {string} displayTotalPrice - (Read only) this.totalPrice as string, formatted with commas and two decimal places.
 * @property {number} itemDiscountAmount - (Read only) The total amount of discounts due to item discounts in this project.
 * @property {string} displayItemDiscountAmount - (Read only) this.itemDiscountAmount as string, formatted with commas and two decimal places.
 * @property {boolean} hasDiscounts - (Read only) Whether or not this project contains items with discounts without override pricing. (Discounts are not typically displayed if any overrides exist)
 * @property {boolean} isValid - (Read only) Whether or not this project contains any invalid items. If true, it cannot be checked out.
 * @property {boolean} isOpen - (Read only) Whether or not this project is in an open state. For a custom quote, this indicates that the quote is not in requote requested state or any other closed / prohibitive state.
 * @property {boolean} isClosed (Read only) Whether or not this project is in a closed state. Projects in this state contain limited information and cannot be interacted with.
 * @property {boolean} isRequoteRequested (Read only) Whether or not this project is currently in a requote requested state. Projects in this state contain limited information and cannot be interacted with.
 * @property {boolean} isDeclined (Read only) Whether or not this project has been declined. Projects in this state contain limited information and cannot be interacted with.
 * @property {boolean} isCart (Read only) Whether or not this project is the cart.
 * @property {boolean} isSaved (Read only) Whether or not this project is the saved for later project.
 * @property {boolean} isSelfServe (Read only) Whether or not this project is a self serve project.
 * @property {boolean} canCheckout - (Read only) Whether or not checkout should be allowed. Can only checkout default project types with valid items.
 * @property {boolean} canEstimateShipping - (Read only) Whether or not shipping can be estimated.
 * @property {boolean} canDecline - (Read only) Whether or not this project can currently be declined.
 * @property {boolean} canRequestRequote - (Read only) Whether or not this project can currently request a requote.
 * @property {boolean} canDelete - (Read only) Whether or not this project can be deleted.
 * @property {boolean} canApplyPromotion - (Read only) Whether or not this project can have a promotion applied.
 * @property {boolean} showPricing - (Read only) Whether or not the storefront's setting indicates we should show pricing information.
 */

class Project {
    static toDB (project) {
        const payload = {
            id: project.id,
            name: project.name,
        };

        // Client not currently represented in Project. Leaving this here for future state, as the API has this as a patch'able field...
        // if (project.client) {
        //     payload.clientId = project.client.id;
        // }

        return payload;
    }

    static getSubtotalForOrderItems (orderItems, discounts = [0]) {
        const subtotal = orderItems.reduce((acc, curr) => acc.plus(curr.totalPrice), new TruncatedDecimal(0, 2));
        const discountTotal = discounts.reduce((acc, curr) => acc.plus(curr), new TruncatedDecimal(0, 2));
        return generateSelectedSubtotal(subtotal.minus(discountTotal).value);
    }

    constructor (data = {}) {
        let {
            name,
            description,
        } = data;

        Object.defineProperties(this, {
            id: {
                value: data.id || null,
                enumerable: true,
            },
            quoteId: {
                value: data.quoteId || null,
                enumerable: true,
            },
            externalQuoteId: {
                value: data.externalQuoteId || '',
                enumerable: true,
            },
            name: {
                ...data.selfServe && {
                    get: () => name,
                    set: newName => name = nullSafeToString(newName),
                },
                ...data.selfServe || {
                    value: name,
                },
                enumerable: true,
            },
            description: {
                ...data.selfServe && {
                    get: () => description,
                    set: newDescription => description = nullSafeToString(newDescription),
                },
                ...data.selfServe || {
                    value: description,
                },
                enumerable: true,
            },
            customerNotes: {
                value: data.customerNotes,
                enumerable: true,
            },
            items: {
                value: Object.freeze(data.items ? data.items.map(item => new OrderItem(item)) : []),
                enumerable: true,
            },
            shippingSummary: {
                value: data.shippingSummary ? Object.freeze(new ShippingSummary(data.shippingSummary)) : null,
                enumerable: true,
            },
            promotion: {
                value: data.promotion ? generatePromotion(data.promotion) : null,
                enumerable: true,
            },
            userDiscount: {
                value: data.userDiscount ? generateUserDiscount(data.userDiscount) : null,
                enumerable: true,
            },
            loyaltyDiscount: {
                value: data.loyaltyCoupon ? generateLoyaltyDiscount(data.loyaltyCoupon) : null,
                enumerable: true,
            },
            status: {
                value: data.status,
                enumerable: true,
            },
            expiresOn: {
                value: new Date(data.expiresOn),
                enumerable: true,
            },
            updatedOn: {
                value: new Date(data.updatedOn),
                enumerable: true,
            },
            quotedOn: {
                value: new Date(data.quotedOn),
                enumerable: true,
            },
            quotedBy: {
                value: data.quotedBy ? new User(data.quotedBy) : null,
                enumerable: true,
            },
            subtotalPrice: {
                value: data.subtotalPrice || 0,
                enumerable: true,
            },
            displaySubtotalPrice: {
                value: intToFormattedString((data.subtotalPrice || 0).toFixed(2)),
                enumerable: true,
            },
            handlingPrice: {
                value: data.handlingPrice || 0,
                enumerable: true,
            },
            displayHandlingPrice: {
                value: intToFormattedString((data.handlingPrice || 0).toFixed(2)),
                enumerable: true,
            },
            creditAccountsDiscountAmount: {
                value: data.creditAccountsDiscountAmount || 0,
                enumerable: true,
            },
            displayCreditAccountsDiscountAmount: {
                value: intToFormattedString((data.creditAccountsDiscountAmount || 0).toFixed(2)),
                enumerable: true,
            },
            taxPrice: {
                value: data.taxPrice || 0,
                enumerable: true,
            },
            displayTaxPrice: {
                value: intToFormattedString((data.taxPrice || 0).toFixed(2)),
                enumerable: true,
            },
            totalPrice: {
                value: data.totalPrice || 0,
                enumerable: true,
            },
            displayTotalPrice: {
                value: intToFormattedString((data.totalPrice || 0).toFixed(2)),
                enumerable: true,
            },
            itemDiscountAmount: {
                value: data.itemDiscountAmount || 0,
                enumerable: true,
            },
            displayItemDiscountAmount: {
                value: intToFormattedString((data.itemDiscountAmount || 0).toFixed(2)),
                enumerable: true,
            },
            hasDiscounts: {
                value: Boolean(data.hasDiscounts),
                enumerable: true,
            },
            isValid: {
                value: Boolean(data.valid),
                enumerable: true,
            },
            isOpen: {
                value: Boolean(data.open),
                enumerable: true,
            },
            isClosed: {
                value: Boolean(data.closed),
                enumerable: true,
            },
            isRequoteRequested: {
                value: Boolean(data.requoteRequested),
                enumerable: true,
            },
            isDeclined: {
                value: Boolean(data.declined),
                enumerable: true,
            },
            isCart: {
                value: Boolean(data.cart),
                enumerable: true,
            },
            isSaved: {
                value: Boolean(data.saved),
                enumerable: true,
            },
            isSelfServe: {
                value: Boolean(data.selfServe),
                enumerable: true,
            },
            canCheckout: {
                // value: Boolean(data.canCheckout), // TODO: Temporary. Delete line below when uncommenting this line.
                get: function () { // TODO: Temporary. Delete these lines when uncommenting the above line.
                    return Boolean(data.canCheckout && !this.items.some(orderItem => orderItem.isPlugin && !orderItem.isPluginReadyForCheckout))
                },
                enumerable: true,
            },
            canEstimateShipping: {
                value: Boolean(data.canEstimateShipping),
                enumerable: true,
            },
            canDecline: {
                value: Boolean(data.canDecline),
                enumerable: true,
            },
            canRequestRequote: {
                value: Boolean(data.canRequestRequote),
                enumerable: true,
            },
            canDelete: {
                value: Boolean(data.canDelete),
                enumerable: true,
            },
            canApplyPromotion: {
                value: Boolean(data.canApplyPromotion),
                enumerable: true,
            },
            showPricing: {
                value: Boolean(data.showPricing),
                enumerable: true,
            },
        });
    }

    /**
     * Fetch an {@link OrderItem order item} by its id
     * @param {number} orderItemId - The id of the order item you wish to fetch
     * @returns {?OrderItem} The requested item or null if item not found
     */

    getItemById (orderItemId) {
        const MIN_ID = 1;

        if (!arguments.length) {
            console.error('Project.getItemById() missing required "orderItemId" argument.');
            return null;
        }

        const id = parseInt(orderItemId, 10);

        if (isNaN(id) || id < MIN_ID) {
            console.error(`Project.getItemById() "orderItemId" argument must be a positive integer. Received: ${orderItemId}.`);
            return null;
        }

        const theItem = this.items.find(item => item.id === id) || null;

        if (!theItem) {
            console.warn(`Project.getItemById() could not find item with id of ${orderItemId}.`);
        }

        return theItem;
    }
}

export default Project;

/**
 * Object containing promotion details
 *
 * @typedef {Object} Project~promotion
 * @property {?string} name - (Read only) The applied promotion's name. Null if invalid code.
 * @property {?string} code - (Read only) The applied promotion's code. Null if invalid code.
 * @property {number} amount - (Read only) The amount of money discounted by this promotion.
 * @property {string} displayAmount - (Read only) this.amount as a string, formatted with commas and two decimal places.
 */

function generatePromotion (data) {
    return Object.defineProperties({}, {
        name: {
            value: data.name || null,
            enumerable: true,
        },
        code: {
            value: data.code || null,
            enumerable: true,
        },
        amount: {
            value: data.amount || 0,
            enumerable: true,
        },
        displayAmount: {
            value: intToFormattedString((data.amount || 0).toFixed(2)),
            enumerable: true,
        },
    });
}

/**
 * Object containing user discount details
 * @typedef {Object} Project~userDiscount
 * @property {string} name - (Read only) The name of the user discount given to this user.
 * @property {number} amount - (Read only) The amount of money discounted by the applied user discount.
 * @property {string} displayAmount - (Read only) this.amount as a string, formatted with commas and two decimal places.
 * @property {number} percent - (Read only) The percent discounted by the applied user discount, out of 100.
 */

function generateUserDiscount (data) {
    return Object.defineProperties({}, {
        name: {
            value: data.name,
            enumerable: true,
        },
        amount: {
            value: data.amount || 0,
            enumerable: true,
        },
        displayAmount: {
            value: intToFormattedString(data.amount.toFixed(2)),
            enumerable: true,
        },
        percent: {
            value: data.percent,
            enumerable: true,
        },
    });
}

/**
 * Object containing loyalty discount details
 * @typedef {Object} Project~loyaltyDiscount
 * @property {string} code - (Read only) The discount code.
 * @property {number} amount - (Read only) The amount of money discounted by the applied loyalty discount.
 * @property {string} displayAmount - (Read only) this.amount as a string, formatted with commas and two decimal places (dollars and cents).
 */

function generateLoyaltyDiscount (data) {
    return Object.defineProperties({}, {
        code: {
            value: data.name,
            enumerable: true,
        },
        amount: {
            value: data.discountAmount || 0,
            enumerable: true,
        },
        displayAmount: {
            value: intToFormattedString(data.discountAmount.toFixed(2)),
            enumerable: true,
        },
    });
}

/**
 * Object containing subtotal details
 * @typedef {Object} Project~selectedSubtotal
 * @property {number} subtotalPrice - The subtotal price as a floating point number.
 * @property {string} subtotalDisplayPrice - The subtotalPrice as string, formatted with commas and two decimal places.
 */

function generateSelectedSubtotal (subTotal) {
    return {
        subtotalPrice: subTotal,
        subtotalDisplayPrice: intToFormattedString(subTotal.toFixed(2)),
    };
}
