import ShippingEstimate from './ShippingEstimate';
import Project from '../Project/Project';
import projectService from '../Project/projectService';
import { typeCheck } from '../lib/util';

/**
 * Shipping API interface
 * @namespace Collaterate.Shipping
 * @example
 * // Most methods on Collaterate.Shipping return a Promise that resolves with a new
 * // {@link ShippingEstimate ShippingEstimate} object. You may wish to keep a local reference to shipping estimate:
 *
 * var shippingEstimate;
 *
 * // You may wish to define a helper promise handler
 * // to keep your local shipping estimate instance updated:
 *
 * function updateShippingEstimate (shippingEstimateResponse) {
 *     // Update local reference to shipping estimate and return it
 *     // to pass along the promise chain.
 *     return shippingEstimate = shippingEstimateResponse;
 * }
 *
 * // Subsequent examples will all assume a local reference
 * // named "shippingEstimate" and a promise handler named "updateShippingEstimate"
 * // that keeps the local shipping estimate up to date with the SDK responses.
 */

export default class ShippingAPI {
    /**
     * Get shipping estimate for a {@link Project Project}
     * @param {Project} project - A project to get shipping estimates for
     * @return {Promise<ShippingEstimate>}
     * @memberof Collaterate.Shipping
     * @example
     * // Get an estimate by passing the project then persist the response
     * Collaterate.Shipping.getEstimate(project)
     *     .then(updateShippingEstimate);
     */

    static async getEstimate (project) {
        typeCheck(project, Project);

        return new ShippingEstimate(await projectService.getShipping(project.id));
    }

    /**
     * Fetches a new shipping estimate based on any changes made to an existing shipping estimate. Should be called every time the country,
     * state or postalCode are changed on the shipping estimate to receive an updated list of options/quotes.
     * @param {ShippingEstimate} shippingEstimate - The estimate to be refreshed
     * @return {Promise<ShippingEstimate>}
     * @memberof Collaterate.Shipping
     * @example
     * // Make a change to an existing estimate
     * shippingEstimate.postalCode = '55046';
     *
     * // Fetch a refreshed estimate to take into account any changes
     * Collaterate.Shipping.refreshEstimate(shippingEstimate)
     *     .then(updateShippingEstimate);
     */

    static async refreshEstimate (shippingEstimate) {
        typeCheck(shippingEstimate, ShippingEstimate);

        return new ShippingEstimate(await projectService.getShipping(...ShippingEstimate.toArray(shippingEstimate)));
    }
}
