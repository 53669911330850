/*
 * A Collaterate storefront product image.
 *
 * @property {number} id - (Read only) This product image's unique id.
 * @property {string} attribution - (Read only) The attribution value assigned to this product image.
 * @property {string} alt - (Read only) The alt text value assigned to this product image.
 * @property {string} src - (Read only) The src value for this product image.
 * @property {boolean} isPrimary - (Read only) Whether or not this product image is marked as the primary image.
 * @property {boolean} isThumbnail - (Read only) Whether or not this product image has been marked as a thumbnail image.
 */

class ProductImage {
    constructor (data) {
        Object.defineProperties(this, {
            id: {
                value: data.id,
                enumerable: true,
            },
            attribution: {
                value: data.attribution || '',
                enumerable: true,
            },
            alt: {
                value: data.imageAlt || '',
                enumerable: true,
            },
            src: {
                value: data.imageUrl,
                enumerable: true,
            },
            isPrimary: {
                value: Boolean(data.primary),
                enumerable: true,
            },
            isThumbnail: {
                value: Boolean(data.thumbnail),
                enumerable: true,
            },
        });
    }
}

export default ProductImage;
