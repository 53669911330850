import PrintFile from '../PrintFile/PrintFile';
import { nullSafeToString, intToFormattedString } from '../lib/util';
import qs from 'qs';

/**
 * An item in a Project
 *
 * @property {number} id - (Read only) This item's unique id.
 * @property {number} projectId - (Read only) Unique id of this item's parent {@link Project Project}.
 * @property {?string} name - The name of this job. Cannot be blank. Null if not applicable.
 * @property {?string} description - (Read only for some OrderItems, check canEditDescription) The description of this job. Null if not applicable.
 * @property {?number} width - (Read only) The width of the finished product. This will be null for stock products.
 * @property {?number} height - (Read only) The height of the finished product. This will be null for stock products.
 * @property {string} configureUrl - (Read only) The url used to reconfigure this job.
 * @property {?string} personalizeUrl - (Read only) The url used to edit a templated product. Null if not applicable.
 * @property {string} productName - (Read only) The name of the product (i.e. "Perfect Bound Booklet" or "3-Leg Scissor Base").
 * @property {string} productIdWithPrefix - (Read only) The id of the product with a prefix identifying the type of offering it is.
 * @property {?string} productImageUrl - (Read only) The url to the large product image. Null if not applicable.
 * @property {?string} productThumbnailUrl - (Read only) The url to the thumbnail product image. Null if not applicable.
 * @property {?string} artworkPreviewUrl - (Read only) The url to a thumbnail preview of the templated artwork. Null if not applicable.
 * @property {number} quantity - (Read only on some items) The quantity ordered. Check item.canAdjustQuantity to determine whether or not this is read only.
 * @property {string} displayQuantity - (Read only) this.quantity as a string, formatted with commas.
 * @property {number} pages - (Read only) The number of pages on products that have them.
 * @property {?string} stockUnitOfMeasure - (Read only) Unit of measure on some stock items. Null if not available.
 * @property {?string} stockVariantDetails - (Read only) Variant details for a stock item or null if not applicable.
 * @property {Date} createdOn - (Read only) The date this order item was quoted.
 * @property {number} price - (Read only) The original, not-discounted, not-overridden price of this order item. Generally only used to display
 * the original price for a discounted, but not overridden price. totalPrice and totalPriceDisplay will show actual price of this item to the user.
 * @property {string} displayPrice - (Read only) this.price as a string, formatted with commas and two decimal places.
 * @property {number} unitPrice - (Read only) The per unit price of this item.
 * @property {number} displayUnitPrice - (Read only) this.unitPrice as a string, formatted with commas and two decimal places.
 * @property {number} totalPrice - (Read only) The final price of this order item to the user. Any discounts or overrides are reflected in this value.
 * @property {string} displayTotalPrice - (Read only) this.totalPrice as a string, formatted with commas and two decimal places.
 * @property {PrintFile[]} printFiles - (Read only) Any print files that have been uploaded for this item.
 * @property {?OrderItem~configDetails} configDetails - (Read only) The configurations details for this item. Null if not applicable to this item.
 * @property {boolean} isValid - (Read only) Whether or not this item is still valid. Cannot order a Project that contains an invalid item.
 * @property {boolean} isPOD - (Read only) Whether or not this is a print on demand product.
 * @property {boolean} isPODStored - (Read only) Whether or not this is a print on demand stored product.
 * @property {boolean} isStock - (Read only) Whether or not this is a stock product.
 * @property {boolean} isTemplated - (Read only) Whether or not this is a templated product.
 * @property {boolean} isPlugin - (Read only) Whether or not this a plugin product.
 * @property {boolean} isPluginReadyForCheckout - (Read only) Whether or not this plugin product is ready for checkout.
 * @property {boolean} isPluginFlagged - (Read only) Whether or not this plugin product has been flagged for assistance.
 * @property {boolean} isPriceOverridden - (Read only) Whether this item has had its price manually adjusted.
 * @property {boolean} isPriceDiscounted - (Read only) Whether or not this item has a discounted totalPrice. False if isPriceOverridden is true.
 * @property {boolean} canConfigure - (Read only) Whether or not this item can be configured.
 * @property {boolean} canDuplicate - (Read only) Whether or not this item can be duplicated.
 * @property {boolean} canUploadFiles - (Read only) Whether or not files can be uploaded to this item.
 * @property {boolean} canPreviewArt - (Read only) Whether or not this item can have artwork previewed.
 * @property {boolean} canAdjustQuantity - (Read only) Whether or not this item can have its quantity adjusted.
 * @property {boolean} canEditDescription - (Read only) Whether or not this item can have its description edited.
 * @property {boolean} hasVersions - (Read only) Whether or not this item has multiple versions.
 * @property {boolean} isTwoSided - (Read only) Whther or not this item is printed on both sides.
 */

class OrderItem {
    static toDB (item) {
        const payload = {
            id: item.id,
            name: item.name,
            description: item.description,
        };

        if (item.canAdjustQuantity) {
            payload.quantity = item.quantity;
        }

        return payload;
    }

    /*
        These fields appear in server response, but are not used/mapped at present:
        - backorderedQuantity
        - orderId
    */

    constructor (data) {
        let {
            name,
            description,
            quoteDescription,
            quantity,
        } = data;

        Object.defineProperties(this, {
            id: {
                value: data.id,
                enumerable: true,
            },
            projectId: {
                value: data.projectId,
                enumerable: true,
            },
            name: {
                get: () => name,
                set: newName => name = nullSafeToString(newName),
                enumerable: true,
            },
            description: {
                get: () => description,
                set: newDescription => description = nullSafeToString(newDescription),
                enumerable: true,
            },
            quoteDescription: {
                ...data.canEditDescription && {
                    get: () => quoteDescription,
                    set: newDescription => quoteDescription = nullSafeToString(newDescription),
                },
                ...data.canEditDescription || {
                    value: quoteDescription,
                },
                enumerable: true,
            },
            width: {
                value: data.width,
                enumerable: true,
            },
            height: {
                value: data.height,
                enumerable: true,
            },
            configureUrl: {
                value: data.configureUrl,
                enumerable: true,
            },
            personalizeUrl: {
                value: data.personalizeUrl || null,
                enumerable: true,
            },
            productName: {
                value: data.productName,
                enumerable: true,
            },
            productIdWithPrefix: {
                value: data.productIdWithPrefix,
                enumerable: true,
            },
            productImageUrl: {
                value: data.productImageUrl,
                enumerable: true,
            },
            productThumbnailUrl: {
                value: data.productThumbnailUrl,
                enumerable: true,
            },
            artworkPreviewUrl: {
                value: data.artworkPreviewUrl,
                enumerable: true,
            },
            quantity: {
                get: () => quantity,
                set: newQuantity => quantity = parseInt(newQuantity, 10),
                enumerable: true,
            },
            displayQuantity: {
                value: intToFormattedString(quantity),
                enumerable: true,
            },
            pages: {
                value: data.configDetails ? getPages(data) : 0,
                enumerable: true,
            },
            stockUnitOfMeasure: {
                value: data.stockUnitOfMeasure || null,
                enumerable: true,
            },
            stockVariantDetails: {
                value: data.stockVariantDetails || null,
                enumerable: true,
            },
            createdOn: {
                value: new Date(data.createdOn),
                enumerable: true,
            },
            price: {
                value: data.price || 0,
                enumerable: true,
            },
            displayPrice: {
                value: intToFormattedString((data.price || 0).toFixed(2)),
                enumerable: true,
            },
            unitPrice: {
                value: data.unitPrice || 0,
                enumerable: true,
            },
            displayUnitPrice: {
                value: intToFormattedString((data.unitPrice || 0).toFixed(2)),
                enumerable: true,
            },
            totalPrice: {
                value: data.totalPrice || 0,
                enumerable: true,
            },
            displayTotalPrice: {
                value: intToFormattedString((data.totalPrice || 0).toFixed(2)),
                enumerable: true,
            },
            printFiles: {
                value: data.files ? Object.freeze(data.files.map(file => new PrintFile(file))) : [],
                enumerable: true,
            },
            configDetails: {
                value: data.configDetails ? generateConfigurationDetails(data) : null,
                enumerable: true,
            },
            isValid: {
                value: data.valid,
                enumerable: true,
            },
            isPOD: {
                value: data.pod,
                enumerable: true,
            },
            isPODStored: {
                value: data.podStored,
                enumerable: true,
            },
            isStock: {
                value: data.stock,
                enumerable: true,
            },
            isTemplated: {
                value: data.templated,
                enumerable: true,
            },
            isPlugin: {
                value: data.plugin,
                enumerable: true,
            },
            isPluginReadyForCheckout: {
                value: data.pluginReadyForCheckout,
                enumerable: true,
            },
            isPluginFlagged: {
                get: () => {
                    const metadata = data.pluginMetaData && qs.parse(data.pluginMetaData);
                    return Boolean(metadata && metadata.flagged && metadata.flagged === 'true');
                },
                enumerable: true,
            },
            isPriceOverridden: {
                value: data.priceOverridden,
                enumerable: true,
            },
            isPriceDiscounted: {
                value: data.priceDiscounted,
                enumerable: true,
            },
            canConfigure: {
                value: data.canConfigure,
                enumerable: true,
            },
            canDuplicate: {
                value: data.canDuplicate,
                enumerable: true,
            },
            canUploadFiles: {
                // value: data.canUploadFiles, // TODO: Temporary. Delete line below when uncommenting this line.
                value: data.canUploadFiles && !data.plugin, // TODO: Temporary. Delete this when uncommenting above.
                enumerable: true,
            },
            canPreviewArt: {
                value: data.canPreviewArt,
                enumerable: true,
            },
            canAdjustQuantity: {
                value: data.canChangeQuantity,
                enumerable: true,
            },
            canEditDescription: {
                value: data.canEditDescription,
                enumerable: true,
            },
            hasVersions: {
                value: Boolean(data.configDetails && data.configDetails.versions && data.configDetails.versions.length > 1),
                enumerable: true,
            },
            isTwoSided: {
                value: data.configDetails ? determineTwoSided(data) : null,
                enumerable: true,
            },
            hasVariableData: {
                value: Boolean(data.configDetails && determineVariableData(data)),
                enumerable: true,
            },
        });
    }

    /**
     * Fetch a {@link PrintFile PrintFile} by its id
     * @param {number} printFileId - The id of the print file you wish to fetch
     * @returns {?PrintFile} The requested print file or null if file not found
     */

    getPrintFileById (printFileId) {
        const MIN_ID = 1;

        if (!arguments.length) {
            console.error('OrderItem.getPrintFileById() missing required "printFileId" argument.');
            return null;
        }

        const id = parseInt(printFileId, 10);

        if (isNaN(id) || id < MIN_ID) {
            console.error(`OrderItem.getPrintFileById() "printFileId" argument must be a positive integer. Received: ${printFileId}.`);
            return null;
        }

        const theFile = this.printFiles.find(printFile => printFile.id === id) || null;

        if (!theFile) {
            console.warn(`OrderItem.getPrintFileById() could not find file with id of ${printFileId}.`);
        }

        return theFile;
    }
}

export default OrderItem;

/**
 * Object containing configuration details for an {@link OrderItem OrderItem}.
 * @typedef {Object} OrderItem~configDetails
 * @property {OrderItem~version[]} versions - (Read only) Array containing information about versions on this OrderItem.
 * @property {OrderItem~keyValue[]} choices - (Read only) Array containing the user product config choices on this OrderItem.
 * @property {OrderItem~keyValue[]} additionalChoices - (Read only) Array containing additional user meta choices on this OrderItem.
 */

/**
 * Object containing a key and a value.
 * @typedef {Object} OrderItem~keyValue
 * @property {string} key - (Read only) The key/label for this item.
 * @property {string} value - (Read only) The value for this item.
 */

/**
 * Object containing OrderItem versions config details.
 * @typedef {Object} OrderItem~version
 * @property {?string} externalId - (Read only) The external id for this version.
 * @property {number} id - (Read only) The id for this version.
 * @property {?string} key - (Read only) The key given for this version.
 * @property {?string} name - (Read only) The name given for this version.
 * @property {?number} number - (Read only) The number given for this version.
 * @property {number} quantity - (Read only) The quantity contained in this version.
 * @property {?string} sku - (Read only) This version's sku.
 * @property {number} orderItemId - (Read only) The OrderItem id this version is associated with.
 * @property {?string} value - (Read only) The value given for this version.
 */

/**
 * An object containing changes to apply to a duplicate item.
 * @typedef {Object} OrderItem~duplicateOptions
 * @property {string} [name] - The name to give the duplicate item.
 * @property {string} [description] - The descripiton to give the duplicate item.
 * @property {number} [quantity] - The total quantity, or if the item contains versions, the quantity per version, to set for the duplicate item.
 */

function generateConfigurationDetails (data) {
    if (data.configDetails.warningMessages && data.configDetails.warningMessages.length) {
        console.warning(`There were problems parsing the configuration details for item ${data.id}.`);
        data.configDetails.warningMessages.forEach(warning => console.warning(warning));
        return null;
    }

    return Object.defineProperties({}, {
        versions: {
            value: Object.freeze(data.configDetails.versions || []),
            enumerable: true,
        },
        choices: {
            value: Object.freeze(data.configDetails.choices || []),
            enumerable: true,
        },
        additionalChoices: {
            value: Object.freeze(data.configDetails.additionalChoices || []),
            enumerable: true,
        },
    });
}

function determineTwoSided (data) {
    const choices = getConfigChoices(data);

    const hasSideOne = Boolean(choices['Printing on the Front']);
    const hasSideTwo = Boolean(choices['Printing on the Back']);
    const hasLFTwoSides = Boolean(choices['Sides']) && Number(choices['Sides']) === 2;

    return (hasSideOne || hasSideTwo) ? (hasSideOne && hasSideTwo) : hasLFTwoSides;
}

function determineVariableData (data) {
    return Boolean(getConfigChoices(data)['Variable Data Personalization']);
}

function getConfigChoices (data) {
    return data.configDetails.choices
        ?
            data.configDetails.choices.reduce((obj, choice) => {
                obj[choice.key] = choice.value;
                return obj;
            }, {})
        :
            {};
}

function getPages (data) {
    const choices = getConfigChoices(data);

    if (choices.hasOwnProperty('Number of Pages')) {
        return Number(choices['Number of Pages']);
    }

    return 0;
}
