/*
Developer's note:

This class exists to normalize the interface between local paginated lists and server paginated lists. This
class will be the one that appears in documentation, so that the SDK consumer has a unified class, interface,
and documentation regardless of whether pagination is local or remote.
*/

/**
 * An object that represents a paginated response of other objects.
 *
 * @property {Object[]} items - The requested data.
 * @property {number} pageSize - The maximum number of items per page.
 * @property {number} totalItems - The total number of items available to return.
 * @property {number} currentPage - The current page number.
 * @property {number} totalPages - The total number of pages available.
 * @property {string} sort - The property the data is currently sorted by.
 */

class PaginatedList {
    constructor (list) {
        this.items = list.items;
        this.pageSize = list.pageSize;
        this.totalItems = list.totalItems;
        this.currentPage = list.currentPage;
        this.totalPages = list.totalPages;
        this.sort = list.sort;

        this.sortBy = list.sortBy.bind(this);
        this.getPage = list.getPage.bind(this);
        this.next = list.next ? list.next.bind(this) : null;
        this.previous = list.previous ? list.previous.bind(this) : null;

    }

    /**
     * Applies sort to paginated list. Applying the same sort to an existing sort flips ascending/descending, if a new sort is being applied, ascending will be true. Sorting will always reset the list to the first page.
     *
     * @returns {Promise<PaginatedList>} A promise that resolves with a new {@link PaginatedList PaginatedList}, sorted by the requested property.
     * @param {string} sortProp - The sort property to apply to the list.
     * @example
     * paginatedList.sortBy("name")
     *     .then(function (newList) {
     *         paginatedList = newList;
     *     });
     */

    async sortBy () {
        return this;
    }

    /**
     * Fetches specified page of results.
     *
     * @returns {Promise<PaginatedList>} A promise that resolves with a new {@link PaginatedList PaginatedList}, containing the requested page of results.
     * @example
     * paginatedList.getPage(2)
     *     .then(function (newList) {
     *         paginatedList = newList;
     *     });
     */

    async getPage () {
        return this;
    }

    /**
     * Fetches the next page of results if available. Otherwise, this property itself will be null.
     *
     * @returns {Promise<PaginatedList>} A promise that resolves with a new {@link PaginatedList PaginatedList}, containing the next page of results.
     * @example
     * // Always check whether next has value before calling it
     * if (paginatedList.next) {
     *     paginatedList.next()
     *         .then(function (newList) {
     *             paginatedList = newList;
     *         });
     * }
     */

    async next () {
        return this;
    }

    /**
     * Fetches the previous page of results if available. Otherwise, this property itself will be null.
     *
     * @returns {Promise<PaginatedList>} A promise that resolves with a new {@link PaginatedList PaginatedList}, containing the previous page of results.
     * @example
     * // Always check whether previous has value before calling it
     * if (paginatedList.previous) {
     *     paginatedList.previous()
     *         .then(function (newList) {
     *             paginatedList = newList;
     *         });
     * }
     */

    async previous () {
        return this;
    }
}

export default PaginatedList;
