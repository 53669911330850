import { typeCheck } from '../lib/util';
import userService from './userService';
import User from './User';
import UserMetadataOption from './UserMetadataOption';

/**
 * SDK interface for user authentication.
 *
 * @namespace Collaterate.User
 */

export default class UserAPI {
    /**
     * Log a user into the storefront.
     *
     * @param {Collaterate.User~loginOptions} loginOptions - Login options object.
     * @returns {Promise<User>} Promise that resolves with a {@link User User}.
     * @throws {CollaterateInputErrorList} If the login credentials are incorrect, will throw a CollaterateInputErrorList type error.
     * @memberof Collaterate.User
     * @example
     * const loginOptions = {
     *     username: document.querySelector('input.username').value,
     *     password: document.querySelector('input.password').value,
     *     rememberMe: document.querySelector('input.rememberMe').value,
     * };
     *
     * Collaterate.User.login(loginOptions)
     *     .then(user => console.log(`${user.username} logged in successfully!`))
     *     .catch(error => console.error('Login unsuccessful!'));
     */

    static async login (loginOptions) {
        typeCheck(loginOptions, Object)

        const {
            username,
            password,
            rememberMe = false,
        } = loginOptions;

        typeCheck(username, String);
        typeCheck(password, String);
        typeCheck(rememberMe, Boolean);

        return new User(await userService.login({ username, password, rememberMe }));
    }

    /**
     * Register a new user with the storefront.
     *
     * @param {Collaterate.User~registrationOptions} registrationOptions - Login options object.
     * @returns {Promise} Promise that resolves on success, there is no response data.
     * @throws {CollaterateInputErrorList} If registration is missing required fields or if any validation errors occur, will throw a CollaterateInputErrorList type error.
     * @memberof Collaterate.User
     * @example
     * const registrationOptions = {
     *     username: document.querySelector('input.username').value,
     *     rememberMe: document.querySelector('input.rememberMe').value,
     *     email: document.querySelector('input.email').value,
     *     firstName: document.querySelector('input.firstName').value,
     *     lastName: document.querySelector('input.lastName').value,
     *     company: document.querySelector('input.company').value,
     *     metadata: [...document.querySelectorAll('.userMetadata')].map(input => ({ label: input.dataset.metadataLabel, value: input.value })),
     * };
     *
     * Collaterate.User.register(registrationOptions)
     *     .then(() => console.log('Registration was successfully submitted. User must check email for verification.'))
     *     .catch(error => console.error('Registration unsuccessful!'));
     */

    static async register (registrationOptions) {
        typeCheck(registrationOptions, Object)

        const {
            username,
            rememberMe = false,
            email = '',
            firstName = '',
            lastName = '',
            company = '',
            metadata = [],
        } = registrationOptions;

        typeCheck(username.trim(), String);
        typeCheck(rememberMe, Boolean);
        typeCheck(email, String);
        typeCheck(firstName, String);
        typeCheck(lastName, String);
        typeCheck(company, String);
        typeCheck(metadata, Array);

        const metaDataOptions = {};
        metadata.forEach(m => metaDataOptions[m.label] = m.value);

        return new User(await userService.register({
            username: username.trim(),
            rememberMe,
            email,
            firstName,
            lastName,
            company,
            metadata: metaDataOptions,
        }));
    }

    /**
     * Get the currently logged in user.
     *
     * @returns {Promise<User>} Promise that resolves with a {@link User User} or null if there is no logged in user
     * @memberof Collaterate.User
     * @example
     * Collaterate.User.getUser()
     *     .then(user => {
     *         if (!user) {
     *             console.log('No user is logged in.');
     *             return;
     *         }
     *
     *         console.log(`${user.username} is logged in.`);
     *     });
     */
    static async getUser () {
        const userResponse = await userService.getUser();

        return userResponse
            ? new User(userResponse)
            : null;
    }

    /**
     * Get the available user metadata options that can be applied to users of this storefront. These are additional fields defined for this storefront to be used during user registration.
     *
     * @returns {Promise<UserMetadataOption[]>} Promise that resolves with an array of {@link UserMetadataOption UserMetadataOption}s
     * @memberof Collaterate.User
     * @example
     * Collaterate.User.getUserMetadataOptions()
     *     .then(userMetadataOptions => {
     *         userMetadataOptions.forEach(userMetadataOption => {
     *             // Do stuff with the metadata info. Most likely, add fields to the registration form.
     *             console.log(`<label for="metadata${userMetadataOption.id}">${userMetadataOption.label}</label>`)
     *
     *             if (!userMetadataOption.isSelect && !userMetadataOption.isTextarea) {
     *                 console.log(`<input type="${userMetadataOption.type}" id="metadata${userMetadataOption.id}" data-metadata-label="${userMetadataOption.label}" />`);
     *                 return;
     *             }
     *
     *             if (userMetadataOption.isTextarea) {
     *                 console.log(`<textarea id="metadata${userMetadataOption.id}" data-metadata-label="${userMetadataOption.label}"></textarea>`);
     *                 return;
     *             }
     *
     *             if (userMetadataOption.isSelect) {
     *                 console.log(`<select id="metadata${userMetadataOption.id}" data-metadata-label="${userMetadataOption.label}">`);
     *                 userMetadataOption.options.forEach(option => {
     *                     console.log(`<option value="${option.value}">${option.label}</option>`);
     *                 });
     *                 console.log('</select>');
     *                 return;
     *             }
     *         });
     *     });
     */
    static async getUserMetadataOptions () {
        const { metadatas } = await userService.getRegistrationOptions();

        return metadatas.map(metadata => new UserMetadataOption(metadata));
    }
}

/**
 * Object containing login information.
 * @typedef {Object} Collaterate.User~loginOptions
 * @property {string} username - The user's username.
 * @property {string} password - The user's password.
 * @property {Boolean} [rememberMe=false] - Remember me. Honestly I don't know what this does. I'll try to find out.
 */

/**
 * Object containing registration information.
 * @typedef {Object} Collaterate.User~registrationOptions
 * @property {string} username - The user's username.
 * @property {Boolean} [rememberMe=false] - Remember me. Honestly I don't know what this does. I'll try to find out.
 * @property {string} email - The user's email.
 * @property {string} firstName - The user's first name.
 * @property {string} lastName - The user's last name.
 * @property {string} [company] - The user's company.
 * @property {Collaterate.User~registrationOptionsMetadata[]} [metadata] - Any metadata for this user.
 */

/**
 * Object containing user metadata information.
 * @typedef {Object} Collaterate.User~registrationOptionsMetadata
 * @property {string} label - The label of the {@link UserMetadataOption UserMetadataOption}.
 * @property {string} value - The user selected value for this metadata option.
 */
