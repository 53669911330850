/** An order item print file
 *
 * @property {number} id - (Read only) This file's unique id.
 * @property {string} name - (Read only) The name of the file.
 * @property {string} filePath - (Read only) The name of the file as it appears in the filesystem.
 * @property {number} fileSize - (Read only) The size of this file in bytes.
 * @property {Date} createdOn - (Read only) The date the file was uploaded.
 */

class PrintFile {
    /*
        The following fields come back in server response but are not mapped at present:
        - category (ex: "PRINT")
    */
    constructor (data) {
        this.id = data.id;
        this.name = data.name;
        this.filePath = data.filePath;
        this.fileSize = data.fileSize;
        this.createdOn = new Date(data.createdOn);
        this.isReorder = data.reorder;

        Object.freeze(this);
    }
}

export default PrintFile;
