/**
 * A named content block for a site, containing HTML content for display.
 *
 * @property {number} id - This block's unique id.
 * @property {string} name - This block's unique name.
 * @property {string} content - This block's HTML content.
 */

class ContentBlock {
    constructor (data) {
        this.id = data.id;
        this.name = data.name;
        this.content = data.content;
    }
}

export default ContentBlock;
