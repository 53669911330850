import MessagesHub from './MessagesHub';
import { typeCheck } from '../lib/util';

/**
 * Messages interface. Allows subscribing to message events from the Collaterate API.
 *
 * @namespace Collaterate.Messages
 */

export default class MessagesAPI {
    /**
     * Add a listener to be notified when there is a new message
     *
     * @param {function} listenerFunction - Function to call when a message is received. The callback is passed one argument, a {@link Message Message} object.
     * @returns {function} Function that unsubscribes the listener that was just registered.
     * @memberof Collaterate.Messages
     * @example
     * // Listen for messages
     * Collaterate.Messages.subscribe(handleMessage);
     *
     * function handleMessage (message) {
     *     console.log('Message received: ' + message.body);
     * }
     * @example
     * // While the SDK provides a stand alone method to unregister a listener,
     * // calling Collaterate.Messages.subscribe() also returns a function that
     * // can be used to unsubscribe the handler that was passed.
     *
     * // Store the result of calling subscribe()
     * var unsubscribe = Collaterate.Messages.subscribe(handleMessage);
     *
     * // Call the function that was returned to unsubscribe
     * unsubscribe();
     */

    static subscribe (fn) {
        typeCheck(fn, Function);

        return MessagesHub.subscribe(fn);
    }

    /**
     * Remove a listener from new message notifications
     *
     * @param {function} listenerFunction - Function to remove from message notifications. This should be a reference to a function that was passed to subscribe().
     * @returns {undefined}
     * @memberof Collaterate.Messages
     * @example
     * // Remove a message listener function
     * Collaterate.Messages.unsubscribe(handleMessage);
     */

    static unsubscribe (fn) {
        return MessagesHub.unsubscribe(fn);
    }

}
