import axios from 'axios';
import { REST_ROOT_URL } from '../config/urls';
import { REQUEST_TIMEOUT } from '../config/constants';
import messageResponseHandler from '../Messages/messageResponseHandler';
import errorResponseHandler from '../errors/errorResponseHandler';
import qs from 'qs';

const MERGE_PATCH_CONTENT_TYPE = 'application/merge-patch+json';
const PATCH_REQUEST_HEADER = { 'Content-Type': MERGE_PATCH_CONTENT_TYPE };

const axiosInstance = axios.create({
    baseURL: REST_ROOT_URL,
    timeout: REQUEST_TIMEOUT,
    // This is not working with current version of Axios, see monkey patch below
    // headers: {
    //     patch: PATCH_REQUEST_HEADER,
    // },
    paramsSerializer: params => qs.stringify(params, {
        allowDots: true,
        // indices: false,
    }),
});

const api = requestConfig => {
    // The below 7 lines of code should not be needed, but Axios is not respecting
    // defualt Content-Type headers, a known bug at this date.
    if (requestConfig.method === 'PATCH') {
        if (requestConfig.headers && !requestConfig.headers['Content-Type']) {
            requestConfig.headers = Object.assign(requestConfig.headers, PATCH_REQUEST_HEADER);
        } else {
            requestConfig.headers = PATCH_REQUEST_HEADER;
        }
    }

    return axiosInstance(requestConfig)
        .then(messageResponseHandler)
        .then(response => {
            if (response.data && response.data.content) {
                return response.data.page
                    ? { content: response.data.content, page: response.data.page }
                    : response.data.content;
            }
            return response.data;
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn(`Request cancelled: ${e.message}`);
                return { cancelled: true, message: e.message };
            }

            if (requestConfig.resolves && e.response) {
                const match = requestConfig.resolves.find(resolve => resolve.status === e.response.status);

                if (match) {
                    return match.hasOwnProperty('data') ? match.data : e.response.data;
                }
            }

            return errorResponseHandler(e);
        });
}

export default api;

export const httpService = requestConfig => {
    return axios(requestConfig)
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn(`Request cancelled: ${e.message}`);
                return { cancelled: true, message: e.message };
            }

            return errorResponseHandler(e);
        });
}

export const CancelToken = axios.CancelToken;
