import CollaterateInputError from './CollaterateInputError';
import errorTypes from './errorTypes';

/**
 * An error that arises when there are problems with input provided by the user. Error container for list of user input errors. User input errors will always come wrapped in this container.
 *
 * @property {string} type - "LIST" - used to determine which type of Collaterate error has been thrown.
 * @property {CollaterateInputError[]} errors - List of individual user input errors
 */

class CollaterateInputErrorList {
    constructor (e) {
        this.type = errorTypes.LIST;
        this.errors = e.errors.map(error => new CollaterateInputError(error));
        this.message = this.errors.reduce((prev, current) => `${prev ? `${prev}, ` : prev}${current.field}: ${current.message}`, '');
    }
}

export default CollaterateInputErrorList;
