import api from '../services/api';

const PRODUCTS_V2_URL = '/products/v2'

export default {
    getById,
    search,
};

// TODO: Response object in this version of the endpoint needs work before it is useful
// INFO: This WAS used by the Canva API, currently unsed in SDK
function getById (idWithPrefix) {
    return api({
        url: `${PRODUCTS_V2_URL}/${idWithPrefix}`,
        method: 'GET',
    });
}

function search (params) {
    return api({
        url: PRODUCTS_V2_URL,
        method: 'GET',
        params: params,
    });
}
