export default class TruncatedDecimal {
    constructor (n, precision) {
        let value = decimalToInt(n, precision);

        const valueOf = () => intToDecimal(value, precision);

        const plus = plusN => {
            value += decimalToInt(plusN, precision);
            return this;
        }

        const minus = minusN => {
            value -= decimalToInt(minusN, precision);
            return this;
        }

        Object.defineProperties(this, {
            plus: {
                value: plus,
            },
            minus: {
                value: minus,
            },
            valueOf: {
                value: valueOf,
            },
            value: {
                get: () => valueOf(),
                enumerable: true,
            },
            toString: {
                value: () => valueOf().toString(),
            },
        });
    }
}

function decimalToInt (n, spaces = 0) {
    let [whole, fraction = ''] = n.toString().split('.');

    if (fraction.length < spaces) {
        while (fraction.length < spaces) {
            fraction += '0';
        }
    } else {
        fraction = fraction.substring(0, spaces);
    }

    return Number(`${whole}${fraction}`);
}

function intToDecimal (n, spaces = 0) {
    const nStr = n.toString();
    const whole = nStr.substring(0, nStr.length - spaces);
    let fraction = nStr.substring(nStr.length - spaces);

    while (fraction.length < spaces) {
        fraction = `0${fraction}`;
    }

    return Number(`${whole}${spaces ? `.${fraction}` : ''}`);
}
