import api from '../services/api';
import { getSiteId } from '../siteId/siteId';
import { STOREFRONT_CHECKOUT_URL } from '../config/urls';

const PROJECTS_V2_URL = '/projects/v2';

const projectService = {
    getAll,
    search,
    getCart,
    getSaved,
    getById,
    updateProject,
    setPromoCode,
    setLoyaltyCode,
    setShippingService,
    removeShippingService,
    duplicateItem,
    removeItem,
    updateItem,
    moveItemToSaved,
    moveItemToCart,
    getShipping,
    getItemTemplatePreview,
    requestRequote,
    decline,
    createProjectFromOrderItemIds,
    getCheckoutUrlFromProjectId,
};

export default projectService;

function getAll (params = {}) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}`,
        method: 'GET',
        params,
    };

    return api(requestConfig);
}

function search (keyword) {
    return getAll({ keyword });
}

function getCart () {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/cart`,
        method: 'GET',
        params: {
            siteId: getSiteId(),
        },
    };

    return api(requestConfig);
}

function getSaved () {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/saved`,
        method: 'GET',
        params: {
            siteId: getSiteId(),
        },
        resolves: [{
            status: 400,
            data: {},
        }, {
            status: 417,
            data: {},
        }],
    };

    return api(requestConfig);
}

function getById (projectId) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}`,
        method: 'GET',
    };

    return api(requestConfig);
}

function updateProject (project) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${project.id}`,
        method: 'PATCH',
        data: project,
    };

    return api(requestConfig);
}

function setPromoCode (projectId, code) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/promo?code=${code}`,
        method: 'POST',
    };

    return api(requestConfig);
}

function setLoyaltyCode (projectId, code) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/loyalty/coupon?code=${code}`,
        method: 'POST',
    };

    return api(requestConfig);
}

function setShippingService (projectId, shippingServiceId, custom) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/shipping/services/${shippingServiceId}?custom=${custom}`,
        method: 'POST',
    };

    return api(requestConfig);
}

function removeShippingService (projectId) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/shipping/services`,
        method: 'DELETE',
    };

    return api(requestConfig);
}

function duplicateItem (projectId, itemId, changes) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/item/${itemId}/duplicate`,
        method: 'POST',
        data: changes,
    };

    return api(requestConfig);
}

function removeItem (projectId, itemId) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/item/${itemId}`,
        method: 'DELETE',
    };

    return api(requestConfig);
}

function updateItem (projectId, item) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/item/${item.id}`,
        method: 'PATCH',
        data: item,
    };

    return api(requestConfig);
}

function moveItemToSaved (projectId, itemId) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/item/${itemId}/save`,
        method: 'POST',
        params: {
            siteId: getSiteId(),
        },
    };

    return api(requestConfig);
}

function moveItemToCart (projectId, itemId) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/item/${itemId}/cart`,
        method: 'POST',
        params: {
            siteId: getSiteId(),
        }
    };

    return api(requestConfig);
}

function getItemTemplatePreview (projectId, itemId, paramArgs) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/item/${itemId}/personalization/previews`,
        method: 'GET',
        params: Object.assign({}, paramArgs),
    };

    return api(requestConfig);
}

function getShipping (projectId, countryId, stateId, postalCode) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/shipping/services`,
        method: 'GET',
        params: {
            countryId,
            stateId,
            postalCode,
        },
    };

    return api(requestConfig);
}

function requestRequote (projectId, notes) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/requestRequote`,
        method: 'POST',
        data: {
            notes,
        },
    };

    return api(requestConfig);
}

function decline (projectId, notes) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/declineQuote`,
        method: 'POST',
        data: {
            notes,
        },
    };

    return api(requestConfig);
}

function createProjectFromOrderItemIds (projectId, orderItemIds) {
    const requestConfig = {
        url: `${PROJECTS_V2_URL}/${projectId}/prepareQuote`,
        method: 'POST',
        data: {
            orderItemIds,
        },
    };

    return api(requestConfig);
}

function getCheckoutUrlFromProjectId (projectId) {
    return `${STOREFRONT_CHECKOUT_URL}${projectId}`;
}
