import projectService from './projectService';
import Project from './Project';
import ProjectListing from './ProjectListing';
import projectSortingService from './projectSortingService';
import OrderItem from '../OrderItem/OrderItem';
import TemplatePreview from '../OrderItem/TemplatePreview';
import ShippingServiceQuote from '../Shipping/ShippingServiceQuote';
import { typeCheck, nullSafeToString } from '../lib/util';
import PaginatedList from '../Pagination/PaginatedList';
import LocalPaginatedList from '../Pagination/LocalPaginatedList';
import PaginationOptions from '../Pagination/PaginationOptions';

/**
 * SDK interface for interacting with Projects.
 * @namespace Collaterate.Project
 */

export default class ProjectAPI {
    /**
     * Get the current user's active cart. A cart is just a {@link Project Project}. Every user has
     * a single {@link Project Project} know as the "cart" at all times.
     * @returns {Promise<Project>} Promise resolves with the "cart" {@link Project Project}
     * @memberof Collaterate.Project
     * @example
     * // Get the current user's cart and log it to the console
     * Collaterate.Project.getCart()
     *     .then(function (cart) {
     *         console.log(cart);
     *     });
     */

    static async getCart () {
        return new Project(await projectService.getCart());
    }

    /**
     * Get the current user's "saved for later" {@link Project Project}. If a user has
     * saved any items for later, a project will be returned containing those items. If
     * a user has not saved any items for later, this request will result in a project with a null id and
     * an empty items array. The "saved for later" project only exists for logged-in users.
     * @returns {Promise<Project>} Promise that resolves with the "saved for later" {@link Project Project}
     * @memberof Collaterate.Project
     * @example
     * // Get the current user's "saved for later" project and log it to the console
     * Collaterate.Project.getSaved()
     *     .then(function (savedForLaterProject) {
     *         if (!savedForLaterProject.id) {
     *             console.log('User has not saved any items for later');
     *         } else {
     *             console.log(savedForLaterProject);
     *         }
     *     });
     */

    static async getSaved () {
        return new Project(await projectService.getSaved());
    }

    /**
     * Get all of the current user's active projects, not including the cart.
     * @returns {Promise<ProjectListing[]>} Returns a Promise that resolves with an array of {@link ProjectListing ProjectListing} objects.
     * @memberof Collaterate.Project
     * @example
     * // Get all of the current user's projects and log them to the console
     * Collaterate.Project.getAll()
     *     .then(function (projects) {
     *         projects.forEach(function (project) {
     *             console.log(project);
     *         });
     *     });
     */

    static async getAll () {
        return projectService.getAll()
            .then(results => results.map(result => new ProjectListing(result)));
    }

    /**
     * Search the current user's active projects by keywords.
     * @param {string} searchPhrase Keyword phrase used to search projects
     * @returns {Promise<ProjectListing[]>} Returns a Promise that resolves with an array of {@link ProjectListing ProjectListing} objects.
     * @memberof Collaterate.Project
     * @example
     * // Search the current user's projects and log each result to the console
     * Collaterate.Project.search("Bananas")
     *     .then(function (projects) {
     *         projects.forEach(function (project) {
     *             console.log(project);
     *         });
     *     });
     */

    static async search (phrase) {
        typeCheck(phrase, String);

        return projectService.search(phrase)
            .then(results => results.map(result => new ProjectListing(result)));
    }

    /**
     * Search the current user's active projects by keywords, paginated.
     * @param {string} searchPhrase Keyword phrase used to search projects
     * @param {PaginatedList~paginationOptions} [paginationOptions] Object of options to control paginated response.
     * @returns {Promise<PaginatedList>} Returns a Promise that resolves with a {@link PaginatedList PaginatedList}, whose items are {@link ProjectListing ProjectListing} objects.
     * @memberof Collaterate.Project
     * @example
     * // Get a paginated list of user project search results and log them to the console
     * Collaterate.Project.paginatedSearch("Bananas", { page: 2 })
     *     .then(function (paginatedList) {
     *         paginatedList.items.forEach(function (project) {
     *             console.log(project);
     *         });
     *     });
     */

    static async paginatedSearch (phrase, paginationOptions) {
        const results = await this.search(phrase);
        const projectListings = results.map(result => new ProjectListing(result));
        const options = new PaginationOptions(paginationOptions);

        return new PaginatedList(new LocalPaginatedList(projectListings, options, this.paginatedSearch.bind(this, phrase), projectSortingService));
    }

    /**
     * Get a paginated list of the current user's active projects, not including the cart.
     * @param {PaginatedList~paginationOptions} [paginationOptions] Object of options to control paginated response.
     * @returns {Promise<PaginatedList>} Returns a Promise that resolves with a {@link PaginatedList PaginatedList}, whose items are {@link ProjectListing ProjectListing} objects.
     * @memberof Collaterate.Project
     * @example
     * // Get a paginated list of user projects and log them to the console
     * Collaterate.Project.getPaginatedList({ page: 2 })
     *     .then(function (paginatedList) {
     *         paginatedList.items.forEach(function (project) {
     *             console.log(project);
     *         });
     *     });
     */

    static async getPaginatedList (paginationOptions) {
        const results = await projectService.getAll();
        const projectListings = results.map(result => new ProjectListing(result));
        const options = new PaginationOptions({ sort: { fieldName: 'updatedOn', ascending: true }, ...paginationOptions })

        return new PaginatedList(new LocalPaginatedList(projectListings, options, this.getPaginatedList.bind(this), projectSortingService));
    }

    /**
     * Get a project by id
     * @param {number} projectId - The id of the project to fetch
     * @returns {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Get a project by it's id, then log the project to the console
     * // Additionally, checks for a 404 in case the ID was not found
     * Collaterate.Project.getById(12345)
     *     .then(function (project) {
     *         console.log(project);
     *     })
     *     .catch(function (error) {
     *         if (error.status === 404) {
     *             console.log('Project not found, if this were a project detail page, we might want to forward to an error page...')
     *         }
     *     })
     */

    static async getById (projectId) {
       const MIN_ID = 1;
       const ERROR_MESSAGE = 'We could not retrieve the details for that project.';

       if (!arguments.length) {
           console.error('Collaterate.Project.getById() missing required "projectId" argument.');
           throw new Error(ERROR_MESSAGE);
       }

       const id = parseInt(projectId, 10);

       if (isNaN(id) || id < MIN_ID) {
           console.error(`Collaterate.Project.getById() "projectId" argument must be a positive integer. Received: ${projectId}.`);
           throw new Error(ERROR_MESSAGE);
       }

       return new Project(await projectService.getById(id));
    }

    /**
     * Save a (modified) project back to the database
     * @param {Project} project - A Project object to save
     * @returns {Promise<Project>} Promise resolves with an updated copy of the {@link Project Project} from the server.
     * @memberof Collaterate.Project
     * @example
     * // Make a change to a local project
     * project.name = "My sweet, sweet printed goods.";
     *
     * // Save the project back to the database, then log the updated response project to the console
     * Collaterate.Project.update(project)
     *     .then(function (project) {
     *         console.log(project);
     *     });
     *
     */

    static async update (project) {
        typeCheck(project, Project);

        return new Project(await projectService.updateProject(Project.toDB(project)))
    }

    /**
     * Set a promo code on a Project and saves any other changes pending on Project
     * @param {Project} project - A Project to apply a promo to
     * @param {string} promoCode - The promo code you wish to apply to Project
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Set a promo code on project, then log the updated response project to the console
     * Collaterate.Project.setPromoCode(project, 'TESTPROMO')
     *     .then(function (project) {
     *         console.log(project)
     *     });
     *
     * // * Note that any other local, yet-to-be-saved changes to Project will also be persisted when you pass it to this operation
     */

    static async setPromoCode (project, promoCode) {
        const code = nullSafeToString(promoCode);
        typeCheck(project, Project);

        await projectService.updateProject(Project.toDB(project));

        return new Project(await projectService.setPromoCode(project.id, code));
    }

    /**
     * Remove a promo code from a Project
     * @param {Project} project - A Project to remove the promo code from
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Remove a promo code from a project, then log the updated response project to the console
     * Collaterate.Project.removePromoCode(project)
     *     .then(function (project) {
     *         console.log(project);
     *     });
     */

    static async removePromoCode (project) {
        return this.setPromoCode(project);
    }

    /**
     * Set a loyalty code on a Project and saves any other changes pending on Project
     * @param {Project} project - A Project to apply a loyalty code to
     * @param {string} loyaltyCode - The loyalty code you wish to apply to Project
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Set a loyalty code on project, then log the updated response project to the console
     * Collaterate.Project.setLoyaltyCode(project, 'TEST-LOYALTY-CODE')
     *     .then(function (project) {
     *         console.log(project)
     *     });
     *
     * // * Note that any other local, yet-to-be-saved changes to Project will also be persisted when you pass it to this operation
     */

     static async setLoyaltyCode (project, loyaltyCode) {
        const code = nullSafeToString(loyaltyCode);
        typeCheck(project, Project);

        await projectService.updateProject(Project.toDB(project));

        return new Project(await projectService.setLoyaltyCode(project.id, code));
    }

    /**
     * Remove a loyalty code from a Project
     * @param {Project} project - A Project to remove the loyalty code from
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Remove a loyalty code from a project, then log the updated response project to the console
     * Collaterate.Project.removeLoyaltyCode(project)
     *     .then(function (project) {
     *         console.log(project);
     *     });
     */

    static async removeLoyaltyCode (project) {
        return this.setLoyaltyCode(project);
    }

    /**
     * Duplicate an item in a Project
     * @param {OrderItem} item - The OrderItem to duplicate in the Project
     * @param {OrderItem~duplicateOptions} [changes] - Any changes to set on the new, duplicate item
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Get an item from a local project by index:
     * var item = project.items[0];
     *
     * // Duplicate the item and update local project with response:
     * Collaterate.Project.duplicateItem(item)
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     *
     * // Duplicate an item, giving it a new quantity, then update the local project with the response:
     * Collaterate.Project.duplicateItem(item, { quantity: 25 })
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     */

    static async duplicateItem (item, changes) {
        typeCheck(item, OrderItem);

        const duplicateChanges = Object.assign({}, changes);

        if (changes) {
            if (duplicateChanges.hasOwnProperty('name')) {
                typeCheck(duplicateChanges.name, String);
            }

            if (duplicateChanges.hasOwnProperty('description')) {
                typeCheck(duplicateChanges.description, String);
            }

            if (duplicateChanges.hasOwnProperty('quantity')) {
                const newQuantity = parseInt(duplicateChanges.quantity, 10);

                if (isNaN(newQuantity) || newQuantity < 0) {
                    throw new TypeError(`Expected positive number, received ${duplicateChanges.quantity} (${typeof duplicateChanges.quantity}).`);
                }

                duplicateChanges.quantity = newQuantity;
            }
        }

        return new Project(await projectService.duplicateItem(item.projectId, item.id, duplicateChanges));
    }

    /**
     * Remove an item from a Project
     * @param {OrderItem} item - The OrderItem to remove from the Project
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Get an item from a local project by id:
     * var itemToRemove = project.getItemById(1234);
     *
     * // Delete the item from the project and update local project with response:
     * Collaterate.Project.removeItem(itemToRemove)
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     */

    static async removeItem (item) {
        typeCheck(item, OrderItem);

        return new Project(await projectService.removeItem(item.projectId, item.id));
    }

    /**
     * Save changes to an item in a Project
     * @param {OrderItem} item - A Project OrderItem to update
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Update an item in a local project in-place:
     * project.items[0].name = 'Awesomesauce Booklet';
     * project.items[0].description = 'Amazingly thoughtful and descriptive description.';
     *
     * // Save the changes to the item and update local project with response:
     * Collaterate.Project.updateItem(project.items[0])
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     */

    static async updateItem (item) {
        typeCheck(item, OrderItem);

        return new Project(await projectService.updateItem(item.projectId, OrderItem.toDB(item)));
    }

    /**
     * Move an item from current project to Saved for Later project. This action is only
     * available to logged-in users.
     * @param {OrderItem} item - The OrderItem to move
     * @returns {Promise<Project>} The updated project (the project the item was moved FROM)
     * @memberof Collaterate.Project
     * @example
     * // Get an item from a local project by id:
     * var itemToMove = project.getItemById(1234);
     *
     * // Move the item to Saved for Later and update local project with the response:
     * Collaterate.Project.moveItemToSaved(itemToMove)
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     *
     * // Note: you will then want to make a call to get the updated saved for later project
     * // Note: this action should only be made available to logged-in users
     */

    static async moveItemToSaved (item) {
        typeCheck(item, OrderItem);

        return new Project(await projectService.moveItemToSaved(item.projectId, item.id));
    }

    /**
     * Move an item from current project to Cart project
     * @param {OrderItem} item - The OrderItem to move
     * @returns {Promise<Project|null>} The updated project (the project the item was moved FROM). Will return null if no items remain in project.
     * @memberof Collaterate.Project
     * @example
     * // Get an item from a local project by id:
     * var itemToMove = project.getItemById(1234);
     *
     * // Move the item to Cart and update local project with the response:
     * Collaterate.Project.moveItemToCart(itemToMove)
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     *
     * // Note: you will then want to make a call to get the updated Cart project
     */

    static async moveItemToCart (item) {
        typeCheck(item, OrderItem);

        return new Project(await projectService.moveItemToCart(item.projectId, item.id));
    }

    /**
     * Get preview images for a templated item
     * @param {OrderItem} item - A templated OrderItem to preview
     * @param {number} [maxPixelDimensions=900] - Pixel dimension to restrain images to.
     * @return {Promise<TemplatePreview>}
     * @memberof Collaterate.Project
     * @example
     * // Get a preview for a templated item in a project and restrain the dimensions to 400px
     * Collaterate.Project.getItemTemplatePreview(project.items[0], 400)
     *     .then(function (templatePreview) {
     *         console.log(templatePreview);
     *     })
     */

    static async getItemTemplatePreview (item, maxPixelDimensions = 900) {
        typeCheck(item, OrderItem);

        if (!item.isTemplated) {
            throw new TypeError('Collaterate.Project.getItemTemplatePreview() requires a templated item.');
        }

        return new TemplatePreview(await projectService.getItemTemplatePreview(item.projectId, item.id, { maxPixelDimensions }));
    }

    /**
     * Sets shipping service on a Project and saves any other changes pending on Project
     * @param {Project} project - The Project to set a shipping service on
     * @param {ShippingServiceQuote} shippingServiceQuote - The quote to set as a Project's shipping service
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Choose a shipping service quote from a shipping estimate's quotes
     * var shippingServiceQuote = shippingEstimate.quotes[0];
     *
     * // Apply the shipping service to a project, then persist the updated project
     * Collaterate.Project.setShippingService(project, shippingServiceQuote)
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     *
     * // * Note that any other local, yet-to-be-saved changes to Project will also be persisted when you pass it to this operation
     */

    static async setShippingService (project, shippingService) {
        typeCheck(project, Project);
        typeCheck(shippingService, ShippingServiceQuote);

        await projectService.updateProject(Project.toDB(project));

        return new Project(await projectService.setShippingService(project.id, shippingService.internalId.shippingServiceId, shippingService.internalId.shippingServiceCustom));
    }

    /**
     * Removes shipping service from a Project and saves any other changes pending on Project
     * @param {Project} project - The Project to remove a shipping service from
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     *
     * // Remove the shipping service from a project, then persist the updated project
     * Collaterate.Project.removeShippingService(project)
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     *
     * // * Note that any other local, yet-to-be-saved changes to Project will also be persisted when you pass it to this operation
     */

    static async removeShippingService (project) {
        typeCheck(project, Project);

        await projectService.updateProject(Project.toDB(project));

        return new Project(await projectService.removeShippingService(project.id));
    }

    /**
     * Moves project into requote requested status
     * @param {number} projectId - The project id to request requote on
     * @param {string} notes - The reason for the requote request
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Request requote on a project, giving the reason why
     * Collaterate.Project.requestRequote(projectId, "Can you please add more cowbell?")
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     */

    static async requestRequote (projectId, notes = '') {
        const id = parseInt(projectId, 10);

        typeCheck(id, Number)
        typeCheck(notes, String);

        return new Project(await projectService.requestRequote(id, notes));
    }

    /**
     * Moves the project into declined status
     * @param {number} projectId - The project id to decline
     * @param {string} notes - The reason for declining the project
     * @return {Promise<Project>}
     * @memberof Collaterate.Project
     * @example
     * // Decline a project, giving the reason why
     * Collaterate.Project.decline(projectId, "Still not enough cowbell.")
     *     .then(function (responseProject) {
     *         project = responseProject;
     *     });
     */

    static async decline (projectId, notes = '') {
        const id = parseInt(projectId, 10);

        typeCheck(id, Number);
        typeCheck(notes, String);

        return new Project(await projectService.decline(id, notes));
    }

    /**
     * Prepares a list of OrderItems for checkout and returns a storefront URL that can be used to initiate the checkout process.
     * @param {number} projectId - The id of the project that contains the order items.
     * @param {number[]} orderItemIds - An array of order item ids to be checked out.
     * @return {Promise<string>} A storefront url that can be used to check out the order items.
     * @memberof Collaterate.Project
     * @example
     * // Get a url to checkout the selected order items, then forward the browser to the checkout page
     * var projectId = someProject.id;
     * var orderItemIds = [1, 2, 3];
     *
     * Collaterate.Project.getCheckoutUrlForOrderItems(projectId, orderItemIds)
     *     .then(function (url) {
     *         window.location = url;
     *     });
     */

    static async getCheckoutUrlForOrderItems (projectId, orderItemIds) {
        const id = parseInt(projectId, 10);

        typeCheck(id, Number);
        typeCheck(orderItemIds, Array);

        const itemIds = orderItemIds.map(itemId => parseInt(itemId, 10));
        itemIds.forEach(itemId => typeCheck(itemId, Number));

        return projectService.getCheckoutUrlFromProjectId(await projectService.createProjectFromOrderItemIds(id, itemIds));
    }

    /**
     * Returns subtotal price object for selected OrderItems
     * @param {OrderItem[]} orderItems - An array of OrderItems to calculate subtotal for
     * @param {number[]} [discounts] - An array of numbers representing any discounts to apply to the subtotal
     * @returns {Project~selectedSubtotal}
     * @memberof Collaterate.Project
     */

    static getSubtotalForOrderItems (orderItems, discounts) {
        typeCheck(orderItems, Array);
        orderItems.forEach(orderItem => typeCheck(orderItem, OrderItem));

        if (discounts) {
            typeCheck(discounts, Array);
            discounts.forEach(discount => typeCheck(discount, Number));
        }

        return Project.getSubtotalForOrderItems(orderItems, discounts);
    }
};
