import { typeCheck } from '../lib/util';

const PRIVATE_KEY = Symbol(`FileUploadList Instance ${Date.now()}${Math.random()}`);

/**
 * Represents a list of {@link FileUpload FileUploads} and their total combined upload progress, with methods to register progress, complete, failure and change callbacks.
 *
 * @property {FileUpload[]} fileUploads - (Read only) An array of individual {@link FileUpload FileUploads}.
 * @property {number} percent - (Read only) The combined upload percentage complete for all file uploads.
 * @property {number} loaded - (Read only) The combined total bytes uploaded for all the file uploads.
 * @property {number} total - (Read only) The combined total filesize in bytes for all the file uploads.
 */

class FileUploadList {
    constructor (fileUploads) {
        const callbacks = {
            progress: [],
            fail: [],
            change: [],
            fileUploadComplete: [],
            complete: [],

        };

        Object.defineProperties(this, {
            fileUploads: {
                value: Object.freeze(fileUploads),
                enumerable: true,
            },
            loaded: {
                get: () => fileUploads.reduce((prev, current) => prev + current.loaded, 0),
                enumerable: true,
            },
            total: {
                get: () => fileUploads.reduce((prev, current) => prev + current.total, 0),
                enumerable: true,
            },
            percent: {
                get: () => (this.loaded / this.total) * 100,
                enumerable: true,
            },
            _addCallback: {
                value: (callback, type, key) => {
                    if (key !== PRIVATE_KEY) {
                        return;
                    }

                    callbacks[type] && callbacks[type].push(callback);
                },
            },
        });

        fileUploads.forEach(fileUpload => {
            fileUpload.progress(() => {
                callbacks.change.forEach(callback => callback(this, fileUpload));
                callbacks.progress.forEach(callback => callback(this, fileUpload));
            });

            fileUpload.promise
                .then(printFile => {
                    callbacks.change.forEach(callback => callback(this, fileUpload));
                    callbacks.fileUploadComplete.forEach(callback => callback(this, fileUpload, printFile));
                })
                .catch(() => {
                    callbacks.change.forEach(callback => callback(this, fileUpload));
                    callbacks.fail.forEach(callback => callback(this, fileUpload));
                });
        });

        Promise.allSettled(fileUploads.map(fileUpload => fileUpload.promise)).then(() => {
            callbacks.change.forEach(callback => callback(this, null));
            callbacks.complete.forEach(callback => callback(this));
        });
    }

    /**
     * Fetch an {@link FileUpload file upload} by its id
     * @param {number} fileUploadId - The id of the file upload you wish to fetch
     * @returns {?FileUpload} The requested file upload or null if item not found
     */

    getUploadById (fileUploadId) {
        const MIN_ID = 1;

        if (!arguments.length) {
            console.error('FileUploadList.getUploadById() missing required "fileUploadId" argument.');
            return null;
        }

        const id = parseInt(fileUploadId, 10);

        if (isNaN(id) || id < MIN_ID) {
            console.error(`FileUploadList.getUploadById() "fileUploadId" argument must be a positive integer. Received: ${fileUploadId}.`);
            return null;
        }

        const theUpload = this.fileUploads.find(upload => upload.id === id) || null;

        if (!theUpload) {
            console.warn(`FileUploadList.getUploadById() could not find an upload with id of ${fileUploadId}.`);
        }

        return theUpload;
    }

    /**
     * Registers a progress callback function for this upload list
     * @param {function} progressCallback - Callback function fired repeatedly during upload progress. The callback function is passed two arguments, this FileUploadList object,
     * and the {@link FileUpload FileUpload} that raised the event.
     * @returns {FileUploadList} this, chainable
     */

    progress (progressCallback) {
        typeCheck(progressCallback, Function);

        this._addCallback(progressCallback, 'progress', PRIVATE_KEY);
        return this;
    }

    /**
     * Registers an error callback function for this upload list
     * @param {function} failCallback - Callback function that fires when any fileUploads have an error. The callback function is passed two arguments, this FileUploadList object,
     * and the {@link FileUpload FileUpload} that raised the event.
     * @returns {FileUploadList} this, chainable
     */

    fail (failCallback) {
        typeCheck(failCallback, Function);

        this._addCallback(failCallback, 'fail', PRIVATE_KEY);
        return this;
    }

    /**
     * Registers a change callback function for this upload list
     * @param {function} changeCallback - Callback function that fires whenever any change in upload state occurs. Any time a progress/fileUploadComplete/complete/fail callback fires,
     * this callback fires first. The callback function is passed two arguments, this FileUploadList object, and the {@link FileUpload FileUpload} that raised the event -
     * except for when this callback fires on complete, then it passes this FileUploadList object as the first argument, null as the second argument.
     * @returns {FileUploadList} this, chainable
     */

    change (changeCallback) {
        typeCheck(changeCallback, Function);

        this._addCallback(changeCallback, 'change', PRIVATE_KEY);
        return this;
    }

    /**
     * Registers a file-upload-complete callback function for this upload list
     * @param {function} fileUploadCompleteCallback - Callback function that fires when any of this object's fileUploads have completed uploading. The callback function is passed three arguments,
     * this FileUploadList object, the {@link FileUpload FileUpload} that completed, and the resulting {@link PrintFile PrintFile} that was created due to the upload.
     * @returns {FileUploadList} this, chainable
     */

    fileUploadComplete (fileUploadCompleteCallback) {
        typeCheck(fileUploadCompleteCallback, Function);

        this._addCallback(fileUploadCompleteCallback, 'fileUploadComplete', PRIVATE_KEY);
        return this;
    }

    /**
     * Registers a complete callback function for this upload list
     * @param {function} completeCallback - Callback function that fires once ALL of this object's fileUploads have completed uploading (or failed). The callback function is passed one argument,
     * this FileUploadList object
     * @returns {FileUploadList} this, chainable
     */

    complete (completeCallback) {
        typeCheck(completeCallback, Function);

        this._addCallback(completeCallback, 'complete', PRIVATE_KEY);
        return this;
    }
}

export default FileUploadList;
