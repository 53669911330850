import ProductImage from "../Product/ProductImage";

/**
 * A Collaterate storefront category listing
 *
 * @property {number} id - (Read only) This category's unique id.
 * @property {string} name - (Read only) This category's name.
 * @property {string} href - (Read only) The path to the category page.
 * @property {string} content - (Read only) The HTML content for the category page.
 * @property {?string} metaDescription - (Read only) The meta description for the category page.
 * @property {?string} metaKeywords - (Read only) The meta keywords for the category page.
 * @property {Object} customAttributes - (Read only) The custom category attributes object.
 * @property {?string} imageUrl - (Read only) The url to the category image if provided.
 * @property {?number} imageHeight - (Read only) The given height for the category image.
 * @property {?number} imageWidth - (Read only) The given width for the category image.
 * @property {?string} imageAlt - (Read only) The alt description for the category image.
 * @property {?string} thumbUrl - (Read only) The url to the category thumbnail image, if provided.
 * @property {?number} thumbHeight - (Read only) The given height for the thumbnail image.
 * @property {?number} thumbWidth - (Read only) The given width for the thumbnail image.
 * @property {?string} thumbAlt - (Read only) The alt descriptiong for the thumbnail image.
 * @property {boolean} showThumb - (Read only) Whether or not the show thumbnail option was selected in site admin.
 * @property {Category[]} children - (Read only) List of child categories.
 * @property {Category~categoryProduct[]} products - (Read only) List of child products.
 */

class Category {
    constructor (data) {
        this.id = Number(data.id);
        this.name = data.name || '';
        this.href = data.href || '';
        this.content = data.content || '';
        this.metaDescription = data.metaDescription || null;
        this.metaKeywords = data.metaKeywords || null;
        this.customAttributes = Object.freeze(Object.assign({}, data.customAttributes));
        this.imageUrl = data.imageUrl || null;
        this.imageHeight = Number(data.imageHeight) || null;
        this.imageWidth = Number(data.imageWidth) || null;
        this.imageAlt = data.imageAlt || null;
        this.thumbUrl = data.thumbUrl || null;
        this.thumbHeight = Number(data.thumbHeight) || null;
        this.thumbWidth = Number(data.thumbWidth) || null;
        this.thumbAlt = data.thumbAlt || '';
        this.showThumb = Boolean(data.showThumb);
        this.children = Object.freeze(data.children.map(child => new Category(child)));
        this.products = Object.freeze(data.products.map(product => createCategoryProduct(product)).sort((a, b) => {
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();
            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        }));

        Object.freeze(this);
    }
}

export default Category;


/**
 * Category Product object
 *
 * @typedef {Object} Category~categoryProduct
 * @property {number} id - (Read only) The product id.
 * @property {string} name - (Read only) The product name.
 * @property {string} href - (Read only) The link to the product page.
 * @property {string} thumbUrl - (Read only) The product image thumbnail url.
 * @property {?number} thumbHeight - (Read only) The given height for the thumbnail.
 * @property {?number} thumbWidth - (Read only) The given width for the thumbnail.
 * @property {?string} thumbAlt - (Read only) The alt description given for the thumbnail.
 * @property {ProductImage[]} images - (Read only) The set of supplied product images.
 */

function createCategoryProduct (data) {
    return Object.freeze({
        id: Number(data.id),
        name: data.name || '',
        href: data.href || '',
        thumbUrl: data.thumbUrl || '',
        thumbHeight: Number(data.thumbHeight) || null,
        thumbWidth: Number(data.thumbWidth) || null,
        thumbAlt: data.thumbAlt || null,
        images: Object.freeze(data.images.map(image => new ProductImage(image))),
    });
}
