let siteId = null;

export const setSiteId = id => {
    if (!siteId) {
        siteId = id;
    }

    return siteId;
}

export const getSiteId = () => siteId;
