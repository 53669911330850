import Country from './Country';
import State from './State';
import ShippingServiceQuote from './ShippingServiceQuote';
import { nullSafeToString } from '../lib/util';
import projectService from '../Project/projectService';

/** An object for representing and working with a shipping estimate for a {@link Project Project}
 *
 * @property {Country[]} countries - (Read only) List of possible countries to ship to.
 * @property {State[]} states - (Read only) List of possible states to ship to, given the current country.
 * @property {?Country} country - The currently selected country for this shipping estimate. Null if not set. (Error field name: countryId)
 * @property {?State} state - The currently selected state for this shipping estimate. Null if not set. (Error field name: stateId)
 * @property {string} postalCode - The postal code, if set, for this shipping estimate.
 * @property {ShippingServiceQuote[]} quotes - (Read only) Any shipping service quotes returned as a result of country/state/zip values.
 */

class ShippingEstimate {
    // Returns array of values in order expected by Collaterate API
    static toArray (shippingEstimate) {
        return [
            shippingEstimate.projectId,
            shippingEstimate.country ? shippingEstimate.country.id : null,
            shippingEstimate.state ? shippingEstimate.state.id : null,
            shippingEstimate.postalCode || '',
        ];
    }

    constructor (data) {
        let country = data.country ? new Country(data.country) : null;
        let state = data.state ? new State(data.state) : null;
        let postalCode = data.postalCode || '';

        Object.defineProperties(this, {
            projectId: {
                value: data.projectId,
            },
            countries: {
                value: Object.freeze(data.countries.map(c => new Country(c))),
                enumerable: true,
            },
            states: {
                value: Object.freeze(data.states ? data.states.map(s => new State(s)) : []),
                enumerable: true,
            },
            country: {
                get: () => country,
                set: function (selectedCountry) {
                    if (!selectedCountry) {
                        state = null;
                        return country = null;
                    }

                    if (selectedCountry.constructor !== Country) {
                        console.warn('Selected country not valid. Country not set.');
                        return;
                    }

                    if (!this.countries.find(c => c.id === selectedCountry.id)) {
                        console.warn('Selected country not found. Country not set.');
                        return;
                    }

                    if (country && country.id === selectedCountry.id) {
                        return country;
                    }

                    // State must be cleared when country changes
                    state = null;

                    // If new country does not support postal codes, clear out postal code
                    if (!selectedCountry.hasPostalCodes) {
                        postalCode = '';
                    }

                    return country = selectedCountry;
                },
                enumerable: true,
            },
            state: {
                get: () => state,
                set: function (selectedState) {
                    if  (!selectedState) {
                        return state = null;
                    }

                    if (selectedState.constructor !== State) {
                        console.warn('Selected state not valid. State not set.');
                        return;
                    }

                    if (!this.states.find(s => s.id === selectedState.id)) {
                        console.warn('Selected state not found. State not set.');
                        return;
                    }

                    return state = selectedState;
                },
                enumerable: true,
            },
            postalCode: {
                get: () => postalCode,
                set: newPostalCode => postalCode = nullSafeToString(newPostalCode),
                enumerable: true,
            },
            quotes: {
                value: Object.freeze(data.estimates.map(estimate => new ShippingServiceQuote(estimate))),
                enumerable: true,
            },
        });
    }

    /**
     * Convenience method to retrieve a country from the countries list by its id
     * @param {number} countryId - The id of the country to retrieve
     * @returns {?Country} The requested country or null if country not found
     */

    getCountryById (countryId) {
        const MIN_ID = 1;

        if (!arguments.length) {
            console.error('ShippingEstimate#getCountryById() missing required "countryId" argument.');
            return null;
        }

        const id = parseInt(countryId, 10);

        if (isNaN(id) || id < MIN_ID) {
            console.error(`ShippingEstimate#getCountryById() "countryId" argument must be a positive integer. Received: ${countryId}.`);
            return null;
        }

        const theCountry = this.countries.find(country => country.id === id) || null;

        if (!theCountry) {
            console.warn(`ShippingEstimate#getCountryById() could not find country with id of ${countryId}.`);
        }

        return theCountry;
    }

    /**
     * Convenience method to retrieve a state from the states list by its id
     * @param {number} stateId - The id of the state to retrieve
     * @returns {?State} The requested state or null if state not found
     */

    getStateById (stateId) {
        const MIN_ID = 1;

        if (!arguments.length) {
            console.error('ShippingEstimate#getStateById() missing required "stateId" argument.');
            return null;
        }

        const id = parseInt(stateId, 10);

        if (isNaN(id) || id < MIN_ID) {
            console.error(`ShippingEstimate#getStateById() "stateId" argument must be a positive integer. Received: ${stateId}.`);
            return null;
        }

        const theState = this.states.find(state => state.id === id) || null;

        if (!theState) {
            console.warn(`ShippingEstimate#getStateById() could not find state with id of ${stateId}.`);
        }

        return theState;
    }

    /**
     * Convenience method to retrieve a shipping service quote from the quotes list by its id
     * @param {number} serviceQuoteId - The id of the shipping service quote to retrieve
     * @returns {?ShippingServiceQuote} The requested shipping service quote or null if shipping service quote not found
     */

    getQuoteById (serviceQuoteId) {
        if (!arguments.length) {
            console.error('ShippingEstimate#getQuoteById() missing required "serviceQuoteId" argument.');
            return null;
        }

        const theQuote = this.quotes.find(quote => quote.id === serviceQuoteId) || null;

        if (!theQuote) {
            console.warn(`ShippingEstimate#getQuoteById() could not find shipping service quote with id of ${serviceQuoteId}.`);
        }

        return theQuote;
    }
}

export default ShippingEstimate;
