export default {
    sort: (list, prop, ascending) => {
        if (!sortBy.hasOwnProperty(prop)) {
            console.warn(`Could not sort by property "${prop}," list unsorted.`);
            return list;
        }

        return ascending
            ? sortBy[prop](list)
            : sortBy[prop](list).reverse();
    },
};

// UpdatedOn is the default / secondary sort
// All date sorts will have their "ascending" flipped by these sorting functions
const sortTwoProjectsByUpdatedOn = (a, b) => b.updatedOn - a.updatedOn;

const sortBy = {
    updatedOn: list => list.sort(sortTwoProjectsByUpdatedOn),

    id: list => list.sort((a, b) => a.id - b.id),

    name: list => list.sort((a, b) => {
        let aName = '';
        let bName = '';

        if (a.name) {
            aName = a.name.toLowerCase();
        }

        if (b.name) {
            bName = b.name.toLowerCase()
        }

        if (aName && !bName) {
            return -1;
        }

        if (!aName && bName) {
            return 1;
        }

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return sortTwoProjectsByUpdatedOn(a, b);
    }),

    quotedBy: list => list.sort((a, b) => {
        if (a.quotedBy.isSelf && !b.quotedBy.isSelf) {
            return -1;
        }

        if (!a.quotedBy.isSelf && b.quotedBy.isSelf) {
            return 1;
        }

        if (a.quotedBy.isSelf && b.quotedBy.isSelf) {
            return sortTwoProjectsByUpdatedOn(a, b);
        }

        let aName = a.quotedBy.name.toLowerCase();
        let bName = b.quotedBy.name.toLowerCase();

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return sortTwoProjectsByUpdatedOn(a, b);
    }),

    // "Ascending" flipped because date
    quotedOn: list => list.sort((a, b) => {
        if (a.quotedOn < b.quotedOn) {
            return 1;
        }

        if (a.quotedOn > b.quotedOn) {
            return -1;
        }

        return sortTwoProjectsByUpdatedOn(a, b);
    }),

    // "Ascending" flipped because date
    expiresOn: list => list.sort((a, b) => {
        if (a.expiresOn < b.expiresOn) {
            return 1;
        }

        if (a.expiresOn > b.expiresOn) {
            return -1;
        }

        return sortTwoProjectsByUpdatedOn(a, b);
    }),
};
