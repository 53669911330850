/** An object to represent a country
 *
 * @property {number} id - (Read only) This country's unique id.
 * @property {string} name - (Read only) This country's name.
 * @property {string} abbreviation - (Read only) The two letter abbreviation for this country.
 * @property {boolean} hasPostalCodes - (Read only) Whether this country uses postal codes.
 * @property {boolean} hasRegions - (Read only) Whether this country uses regions (states).
 */

class Country {
    constructor (data) {
        this.id = data.id;
        this.name = data.name;
        this.abbreviation = data.abbreviation;
        this.hasPostalCodes = data.hasPostalCodes;
        this.hasRegions = data.hasRegions;

        Object.freeze(this);
    }
}

export default Country;
