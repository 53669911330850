import User from '../User/User';

/**
 * A lightweight, listing representation of a {@link Project Project}.
 *
 * @property {number} id - (Read only, sortable) This project's unique id.
 * @property {?number} quoteId - (Read only) This project's custom quote id, if it is one.
 * @property {string} name - (Read only, sortable) This project's name.
 * @property {User} quotedBy - (Read only, sortable) The user that created this project.
 * @property {Date} quotedOn - (Read only, sortable) The date of this project's creation.
 * @property {Date} updatedOn - (Read only, sortable) The date this project was last updated.
 * @property {Date} expiresOn - (Read only, sortable) The expiration date of this project.
 * @property {boolean} isSelfServe - (Read only) Whether this project was created by the storefront user (true) or an admin (false).
 * @property {boolean} canDelete - (Read only) Whether or not this project can be deleted by this user, in its current state.
 * @property {boolean} canDecline - (Read only) Whether or not this project can be declined by this user, in its current state.
 * @property {boolean} canRequestRequote - (Read only) Whether or not this user can request a requote on this project in its current state.
 */

class ProjectListing {
    constructor (data) {
        this.id = data.id;
        this.quoteId = data.quoteId || null;
        this.name = data.name;
        this.quotedBy = Object.freeze(new User(data.quotedBy));
        this.quotedOn = Object.freeze(new Date(data.quotedOn));
        this.updatedOn = Object.freeze(new Date(data.updatedOn));
        this.expiresOn = Object.freeze(new Date(data.expiresOn));
        this.isSelfServe = data.selfServe;
        this.canDelete = data.canDelete;
        this.canDecline = data.canDecline;
        this.canRequestRequote = data.canRequestRequote;

        Object.freeze(this);
    }

}

export default ProjectListing;

