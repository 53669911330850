import api from '../services/api';
import { getSiteId } from '../siteId/siteId';

const CATEGORIES_V2_URL = '/categories/v2';

const categoryService = {
    getCategories,
};

export default categoryService;

function getCategories () {
    const requestConfig = {
        url: CATEGORIES_V2_URL,
        method: 'GET',
        params: {
            siteId: getSiteId(),
        }
    }

    return api(requestConfig);
}
