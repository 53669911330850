import { typeCheck } from '../lib/util';
import productService from './productService';
import Product from './Product';
import PaginatedList from '../Pagination/PaginatedList';
import PaginationOptions from '../Pagination/PaginationOptions';
import RemotePaginatedList from '../Pagination/RemotePaginatedList';

/**
 * SDK interface for working with Collaterate Products.
 *
 * @namespace Collaterate.Product
 */

export default class ProductAPI {

    /**
     * Search for a paginated list of products.
     * @param {Collaterate.Product~productSearchOptions} [productSearchOptions] - Options object containing product search criteria.
     * @param {PaginatedList~paginationOptions} [paginationOptions] - Object of options to control paginated response.
     * @returns {Promise<PaginatedList>} Returns a Promise that resolves with a {@link PaginatedList PaginatedList}, whose items are {@link Product Product} objects.
     * @memberof Collaterate.Product
     * @example
     * // Get a paginated list of products, store the PaginatedList response, and log the products to the console
     * let paginatedProductList;
     *
     * Collaterate.Product.search({ keyword: 'booklet' }, { page: 2 })
     *     .then(paginatedList => {
     *         paginatedProductList = paginatedList;
     *     })
     *     .then(() => {
     *         paginatedProductList.items.forEach(console.log);
     *     });
     */

    static async search (productSearchOptions, paginationOptions) {
        productSearchOptions && typeCheck(productSearchOptions, Object);
        paginationOptions && typeCheck(paginationOptions, Object);

        const pageOptions = new PaginationOptions({
            sort: {
                fieldName: 'name',
                ascending: true,
            },
            ...paginationOptions
        });

        const { content, page } = await productService.search({
            ...productSearchOptions,
            page: pageOptions,
        });

        return new PaginatedList(new RemotePaginatedList(content.map(item => new Product(item)), page, this.search.bind(this, productSearchOptions)))
    }
}

/**
 * Product search options object.
 *
 * @typedef {Object} Collaterate.Product~productSearchOptions
 * @property {string} [keyword] - Key word or phrase to search for.
 * @property {number[]} [categoryIds] - Array of categories to fetch. Omit to search for all categories.
 */
