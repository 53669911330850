import api from '../services/api';
import { getSiteId } from '../siteId/siteId';

const MENUS_V2_URL = '/menus/v2';

const menuService = {
    getMenuByName,
};

export default menuService;

function getMenuByName (name = '') {
    const requestConfig = {
        url: MENUS_V2_URL,
        method: 'GET',
        params: {
            name,
            siteId: getSiteId(),
        }
    }

    return api(requestConfig);
}
