import ProductImage from './ProductImage';

/*
 * A Collaterate storefront product.
 *
 * @property {number} id - (Read only) This product’s database id. See idWithPrefix- the id alone is not guaranteed to be unique.
 * @property {string} idWithPrefix - (Read only) This product’s unique id. The id of the product with a prefix identifying the type of offering it is.
 * @property {?number} variantId - (Read only) If this is a variant of a product, the variant’s unique id.
 * @property {string} name - (Read only) The name of the product.
 * @property {string} description - (Read only unless self-serve) The description for this product.
 * @property {string} excerpt - (Read only unless self-serve) The excerpt for this product.
 * @property {ProductImage[]} images (Read only) The {@link ProductImage product images} assigned to this product.
 * @property {string} urlPattern - (Read only) The absolute link to this product's product page.
 */

class Product {
    constructor (data) {
        Object.defineProperties(this, {
            id: {
                value: data.id,
                enumerable: true,
            },
            idWithPrefix: {
                value: data.idWithPrefix,
                enumerable: true,
            },
            variantId: {
                value: data.variantId || null,
                enumerable: true,
            },
            name: {
                value: data.name,
                enumerable: true,
            },
            description: {
                value: data.description || '',
                enumerable: true,
            },
            excerpt: {
                value: data.excerpt || '',
                enumerable: true,
            },
            images: {
                value: Object.freeze(data.images.map(image => new ProductImage(image))),
                enumerable: true,
            },
            urlPattern: {
                value: data.urlPattern || '',
                enumerable: true,
            }
        });
    }
}

export default Product;
