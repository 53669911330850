import { clamp } from '../lib/util';
import { paginationArgumentsFromPagedResponse, sortOptionsFromPagedResponse } from './PaginationOptions';

export default class RemotePaginatedList {
    constructor (list, paginatedResponse, dataSource) {
        const [ primarySort, ...secondarySorts ] = paginatedResponse.sort;
        const paginationOptions = paginationArgumentsFromPagedResponse(paginatedResponse);

        this.items = list;
        this.pageSize = paginatedResponse.size;
        this.totalItems = paginatedResponse.totalElements;
        this.totalPages = paginatedResponse.totalPages;
        this.currentPage = paginatedResponse.number;
        this.sort = sortOptionsFromPagedResponse(primarySort);
        this.secondarySorts = secondarySorts.map(sortOptionsFromPagedResponse);


        this.sortBy = sort => getPage({
            ...paginationOptions,
            ...{
                page: 1,
                sort: {
                    fieldName: sort,
                    ascending: sort === this.sort.fieldName
                        ? !this.sort.ascending
                        : true,
                },
            }
        }, dataSource);

        this.getPage = page => getPage({
            ...paginationOptions,
            ...{ page: clamp(page, 1, this.totalPages) }
        }, dataSource);

        this.next = this.currentPage < this.totalPages
            ? getPage.bind(null, { ...paginationOptions, ...{ page: this.currentPage + 1 } }, dataSource)
            : null;

        this.previous = this.currentPage > 1
            ? getPage.bind(null, { ...paginationOptions, ...{ page: this.currentPage - 1 } }, dataSource)
            : null;
    }
}

function getPage (paginationOptions, dataSource) {
    return dataSource(paginationOptions);
}
