import { httpService } from '../services/api';
import { ERROR_URL } from '../config/urls';

const errorType = 'Collaterate SDK HTTP Error';

const errorLogger = {
    log,
};

export default errorLogger;

function log (e, error) {
    const logMessage = error && error.message
        ? error.message
        : e.message;

    console.error(`${errorType}: ${logMessage}`);

    if (e.config && e.config.data) {
        let data = JSON.parse(e.config.data);

        e.config.data = JSON.stringify({
            ...data,
            ...(data.password ? { password: '*****' } : {}),
        });
    }

    logToServer({
        message: e.message,
        href: window.location.href,
        type: errorType,
        config: e.config,
        request: e.request,
        response: e.response,
        ua: window.navigator.userAgent,
    });

    return e;
}

function logToServer (data) {
    const requestConfig = {
        url: ERROR_URL,
        method: 'PUT',
        data: { json: data }
    };

    return httpService(requestConfig)
        .then(() => data)
        .catch(e => {
            console.error('Could not log error to server.');
            return Promise.reject(e);
        });
}
