let observers = [];

export default class MessagesHub {
    static subscribe (fn) {
        observers.push(fn);
        return MessagesHub.unsubscribe.bind(null, fn);
    }

    static unsubscribe (fn) {
        observers = observers.filter(observer => observer !== fn);
    }

    static trigger (message) {
        observers.forEach(observer => observer(message));
    }
};
