import categoryService from '../Category/categoryService';
import Category from '../Category/Category';
import contentBlockService from '../ContentBlock/contentBlockService';
import ContentBlock from '../ContentBlock/ContentBlock';
import menuService from '../Menu/menuService';
import Menu from '../Menu/Menu';
import { typeCheck } from '../lib/util';

/**
 * Site interface, dealing with data and metadata specific to each storefront.
 * @namespace Collaterate.Site
 */

export default class SiteAPI {

    /**
     * Retrieve site category tree
     * @return {Promise<Category[]>}
     * @memberof Collaterate.Site
     * @example
     * // Get this site's category tree then log the results to the console
     * Collaterate.Site.getCategories()
     *     .then(function (categories) {
     *         categories.forEach(console.log);
     *     });
     */

    static async getCategories () {
        return categoryService.getCategories()
            .then(results => results.map(result => new Category(result)));
    }

    /**
     * Get a single category by id
     * @return {Promise<Category>}
     * @memberof Collaterate.Site
     * @param {number} id - The id of the category to fetch
     * @example
     *
     * Collaterate.Site.getCategoryById(123)
     *     .then(category => {
     *         console.log(category.name);
     *     });
     */
    static async getCategoryById (id) {
        typeCheck(id, Number);

        const category = findById((await this.getCategories()), id);

        if (!category) {
            console.warn(`Collaterate.Site.getCategoryById could not find category with id of ${id}.`);
        }

        return category;
    }

    /**
     * Retrieve a content block by its name
     * @param {string} name - The name of the content block to retrieve.
     * @return {Promise<ContentBlock>}
     * @memberof Collaterate.Site
     * @example
     * // Get a content block by its name then log its html to the console
     * Collaterate.Site.getContentBlockByName("upload_instructions")
     *     .then(function (contentBlock) {
     *         console.log(contentBlock.content);
     *     });
     */

    static async getContentBlockByName (name) {
        return new ContentBlock(await contentBlockService.getByName(name));
    }

    /**
     * Retrieve a site menu by its name
     * @param {string} name - The name of the menu to retrieve.
     * @return {Promise<Menu>}
     * @memberof Collaterate.Site
     * @example
     * // Get a menu by its name then log the result to the console
     * Collaterate.Site.getMenuByName("footer-menu")
     *     .then(function (menu) {
     *         console.log(menu);
     *     });
     */

    static async getMenuByName (name) {
        return new Menu(await menuService.getMenuByName(name));
    }
}

function findById (categories, id) {
    const category = categories[0];

    if (!categories.length) {
        return null;
    }

    if (category.id === id) {
        return category;
    }

    return findById([...category.children, ...categories.slice(1)], id);
}
