/**
 * Abstract error class used by errors raised via Collaterate
 *
 * @property {string} message - A user-facing error message generated by Collaterate
 * @property {string} title - A user-facing title for the error generated by Collaterate
 * @property {string} id - Unique id generated for this instance of an error, can be used to find in logs
 * @property {string} code - The Collaterate code representing this error
 * @property {number} status - The HTTP error status code
 */

class AbstractCollaterateError {
    constructor (e) {
        this.message = e.detail;
        this.title = e.title;
        this.id = e.id || null;
        this.code = e.code;
        this.status = parseInt(e.status, 10);
        this.meta = e.meta || {}; // Undocumented, meta error message generated by the server
    }
}

export default AbstractCollaterateError;
