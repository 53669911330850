import MessageHub from '../Messages/MessagesHub';
import Message from '../Messages/Message';

const messageResponseHandler = response => {
    if (response.data.messages && response.data.messages.length) {
        response.data.messages.forEach(message => MessageHub.trigger(new Message(message)));
    }

    return response;
}

export default messageResponseHandler;
