import loyaltyService from './loyaltyService';

/**
 * Loyalty API interface
 * @namespace Collaterate.Loyalty
 */

export default class LoyaltyAPI {
    /**
     * Get auth details for site loyalty program
     * @returns {Promise<Collaterate.Loyalty~authDetails>}
     * @memberof Collaterate.Loyalty
     * @example
     * Collaterate.Loyalty.getAuth()
     *     .then(console.log);
     */

    static getAuth () {
        return loyaltyService.getGUID()
            .then(r => ({
                guid: r.yotpoGuid,
            }));
    }
}

/**
 * Object containing auth details for this site's loyalty integration
 * @typedef {Object} Collaterate.Loyalty~authDetails
 * @property {string} guid - The guid value set in this site's loyalty settings. Used to initialize UI modules.
 */
