import { intToFormattedString } from '../lib/util';

/** An object representing a shipping service quote
 *
 * @property {string} id - (Read only) The id of this quote.
 * @property {number} price - (Read only) The cost of this quote.
 * @property {string} displayPrice - (Read only) this.price as string, formatted with commas and two decimal places.
 * @property {string} description - (Read only) The label for this shipping service.
 * @property {string} vendorName - (Read only) The name of this shipping service vendor.
 * @property {?number} daysInTransit - (Read only) The number of days in transit for this shipping service. Null if not applicable (e.g. Chanhassen Pick Up).
 */

class ShippingServiceQuote {
    constructor (data) {
        this.id = `${data.id.projectId}-${data.id.shippingServiceCustom ? 1 : 0}-${data.id.shippingServiceId}`;
        this.price = data.price;
        this.displayPrice = intToFormattedString(this.price.toFixed(2));
        this.description = data.shippingServiceDescription;
        this.vendorName = data.shippingVendorName;
        this.daysInTransit = data.daysInTransit;
        this.internalId = Object.freeze(data.id); // Not documented, used internally by SDK. { projectId, shippingServiceCustom, shippingServiceId }

        Object.freeze(this);
    }
}

export default ShippingServiceQuote;
