import AbstractCollaterateError from './AbstractCollaterateError';
import errorTypes from './errorTypes';

/**
 * An error that arises when there are problems with input provided by the user.
 *
 * @extends AbstractCollaterateError
 * @property {string} type - "INPUT" - used to determine which type of Collaterate error has been thrown.
 * @property {string} field - Which field or property is in error state. Could be a nested object property. (e.g.- orderShipment.id would reference the id field of an orderShipment object)
 */

class CollaterateInputError extends AbstractCollaterateError {
    constructor (e) {
        super(e);

        this.type = errorTypes.INPUT;
        this.field = e.source.parameter || e.source.pointer;
    }
}

export default CollaterateInputError;
