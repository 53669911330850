export const nullSafeToString = input => (input === null || typeof input === 'undefined' ? '' : input.toString());

export const strWeb = str => {
    return nullSafeToString(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&apos;')
        .replace(/"/g, '&quot;');
}

export const typeCheck = (arg, type) => {
    if (typeof arg === 'undefined' || (arg.constructor !== type && !(arg instanceof type))) {
        throw new TypeError(`Expected ${type.name}, received ${arg ? arg.constructor.name : typeof arg}.`);
    }

    return true;
}

export const intToFormattedString = int => int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // http://stackoverflow.com/a/2901298

export const decimalToInt = (n, spaces = 0) => {
    let [whole, fraction = ''] = n.toString().split('.');

    if (fraction.length < spaces) {
        while (fraction.length < spaces) {
            fraction += '0';
        }
    } else {
        fraction = fraction.substring(0, spaces);
    }

    return Number(`${whole}${fraction}`);
}

export const intToDecimal = (n, spaces = 0) => {
    const nStr = n.toString();
    const whole = nStr.substring(0, nStr.length - spaces);
    let fraction = nStr.substring(nStr.length - spaces);

    while (fraction.length < spaces) {
        fraction = `0${fraction}`;
    }

    return Number(`${whole}${spaces ? `.${fraction}` : ''}`);
}

export const clamp = (n, min, max) => {
    const num = Number(n);

    if (isNaN(num) || n < min) {
        return min;
    }

    if (n > max) {
        return max;
    }

    return num;
}
