import messageTypes from './messageTypes';

/**
 * A Message delivered as part of a Collaterate API request
 *
 * @property {string} body - (Read only) The message text
 * @property {string} type - (Read only) The type of message, one of "SUCCESS"|"INFO"|"WARNING"
 * @property {boolean} isSuccess - (Read only) Whether this message is of type SUCCESS
 * @property {boolean} isInfo - (Read only) Whether this message is of type INFO
 * @property {boolean} isWarning - (Read only) Whether this message is of type WARNING
 */

class Message {
    constructor (data) {
        Object.defineProperties(this, {
            body: {
                value: data.detail,
                enumerable: true,
            },
            type: {
                value: messageTypes[data.type] || '',
                enumerable: true,
            },
            meta: {
                value: data.meta || {},
                /*
                From Jeff: "in any warning messages back from shipping estimates endpoint, there should now be a meta.shippingVendorName attribute"
                Consider: do we want to start enumerating specific possibilities for meta?
                */
            },
            isSuccess: {
                value: data.type === messageTypes.SUCCESS,
                enumerable: true,
            },
            isInfo: {
                value: data.type === messageTypes.INFO,
                enumerable: true,
            },
            isWarning: {
                value: data.type === messageTypes.WARNING,
                enumerable: true,
            },
        });

        if (!this.type) {
            console.warn('Collaterate: Message type could not be determined.');
        }
    }
}

export default Message;
