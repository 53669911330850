import api from '../services/api';

const service = {
    login,
    register,
    getRegistrationOptions,
    getUser,
};

export default service;

function login (data) {
    return api({
        url: '/users/v2/login',
        method: 'POST',
        data,
    });
}

function register (data) {
    return api({
        url: '/users/v2/registration',
        method: 'POST',
        data,
    });
}

function getRegistrationOptions () {
    return api({
        url: '/users/v2/registration',
        method: 'GET'
    });
}

function getUser () {
    return api({
        url: '/users/v2/current',
        method: 'GET',
        resolves: [{
            status: 401,
            data: null,
        }],
    });
}
