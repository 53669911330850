/** An object to represent a state (reqion)
 *
 * @property {number} id - (Read only) This state's unique id.
 * @property {string} name - (Read only) This state's name.
 * @property {string} abbreviation - (Read only) The abbreviation for this state (region).
 */

class State {
    constructor (data) {
        this.id = data.id;
        this.name = data.name;
        this.abbreviation = data.abbreviation;

        Object.freeze(this);
    }
}

export default State;
