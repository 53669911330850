import { clamp } from '../lib/util';
import { paginationArgumentsFromPagedResponse, sortOptionsFromPagedResponse } from './PaginationOptions';

export default class LocalPaginatedList {
    constructor (list, paginatedResponse, dataSource, sortingService) {
        const [ primarySort, ...secondarySorts ] = paginatedResponse.sort;
        const paginationOptions = paginationArgumentsFromPagedResponse(paginatedResponse);

        this.pageSize = paginatedResponse.size;
        this.totalItems = list.length;
        this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        this.currentPage = clamp(paginatedResponse.number, 1, this.totalPages);
        this.sort = sortOptionsFromPagedResponse(primarySort);
        this.secondarySorts = secondarySorts.map(sortOptionsFromPagedResponse)

        const items = sortingService
            ? sortingService.sort(list, this.sort.fieldName, this.sort.ascending)
            : this.sort.ascending ? list : list.reverse();

        const currentIndex = (this.currentPage - 1) * this.pageSize;
        this.items = items.slice(currentIndex, currentIndex + this.pageSize);

        this.sortBy = sort => getPage({
            ...paginationOptions,
            ...{
                page: 1,
                sort: {
                    fieldName: sort,
                    ascending: sort === this.sort.fieldName
                        ? !this.sort.ascending
                        : true,
                },
            }
        }, dataSource);

        this.getPage = page => getPage({
            ...paginationOptions,
            ...{ page: clamp(page, 1, this.totalPages) }
        }, dataSource);

        this.next = this.currentPage < this.totalPages
            ? getPage.bind(null, { ...paginationOptions, ...{ page: this.currentPage + 1 } }, dataSource)
            : null;

        this.previous = this.currentPage > 1
            ? getPage.bind(null, { ...paginationOptions, ...{ page: this.currentPage - 1 } }, dataSource)
            : null;
    }
}

function getPage (paginationOptions, dataSource) {
    return dataSource(paginationOptions);
}
