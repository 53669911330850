import userMetadataOptionTypes from './userMetadataOptionTypes';

/**
 * Metadata options that are available to be applied to users of this storefront.
 *
 * @property {number} id - (Read only) The unique id for this option.
 * @property {string} label - (Read only) The user facing label for this option.
 * @property {UserMetadataOption~type} type - (Read only) The type of input required for collecting this metadata from the user.
 * @property {UserMetadataOption~option[]} options - (Read only) If `this.type` is "select", the options that are available to choose from.
 * @property {boolean} isRequired - (Read only) Whether or not this is a required field.
 * @property {boolean} isCheckbox - (Read only) True if `this.type` is "checkbox".
 * @property {boolean} isText - (Read only) True if `this.type` is "text".
 * @property {boolean} isTextarea - (Read only) True if `this.type` is "textarea".
 * @property {boolean} isSelect - (Read only) True if `this.type` is "select".
 * @property {boolean} isDate - (Read only) True if `this.type` is "date".
 */

class UserMetadataOption {
    constructor (data) {
        this.id = data.id;
        this.label = data.label;
        this.type = userMetadataOptionTypes[data.type];
        this.options = Object.freeze(Object.keys(data.potentialValuesById).map(key => ({ label: data.potentialValuesById[key], value: key })));
        this.isRequired = data.required;
        this.isCheckbox = this.type === userMetadataOptionTypes.CHECK_BOX;
        this.isText = this.type === userMetadataOptionTypes.TEXT_BOX;
        this.isTextarea = this.type === userMetadataOptionTypes.TEXT_AREA;
        this.isSelect = this.type === userMetadataOptionTypes.COMBO_BOX;
        this.isDate = this.type === userMetadataOptionTypes.DATE_PICKER;

        Object.freeze(this);
    }
}

export default UserMetadataOption;

/**
 * @typedef {"checkbox" | "text" | "textarea" | "select" | "date"} UserMetadataOption~type
 */

/**
 * @typedef {Object} UserMetadataOption~option
 * @property {string} label - The user facing label for this option
 * @property {string} value - The value for this option
 */

