import userTypes from './userTypes';

/* StorefrontUserDTO */

/**
 * A storefront user.
 *
 * @property {string} firstName - (Read only) The user's first name.
 * @property {string} lastName - (Read only) The user's last name.
 * @property {string} name - (Read only) The user's full name.
 * @property {string} email - (Read only) The user's email address.
 * @property {string} phone - (Read only) The user's phone number.
 * @property {string} username - (Read only) The user's username. Keep in mind that admin user's usernames are from Collaterate, and not the storefront itself.
 * @property {string} company - (Read only) The user's company.
 * @property {boolean} isSelf - (Read only) Is this user the same as the currently logged in user?
 * @property {boolean} isStorefrontUser - (Read only) Is this user a storefront user?
 * @property {boolean} isAdminUser - (Read only) Is this user an admin user?
 */

class User {
    constructor (data) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.name = data.name;
        this.email = data.email;
        this.phone = data.phone;
        this.username = data.username;
        this.company = data.company || '';
        this.isSelf = data.self;
        this.isStorefrontUser = data.type === userTypes.STOREFRONT;
        this.isAdminUser = data.type === userTypes.ADMIN;

        Object.freeze(this);
    }
}

export default User;
