import api from '../services/api';

const CONTENT_BLOCKS_V2_URL = '/contentBlocks/v2';

const contentBlockService = {
    getByName
};

export default contentBlockService;

function getByName (name) {
    const requestConfig = {
        url: `${CONTENT_BLOCKS_V2_URL}/name/${name}`,
        method: 'GET',
    };

    return api(requestConfig);
}
