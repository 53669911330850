import { SDK_SCRIPT_ID } from './config/constants';
import bootstrap from './sdk/bootstrap';
import AssetAPI from './Assets/AssetsAPI';
import LoyaltyAPI from './Loyalty/LoyaltyAPI';
import MessagesAPI from './Messages/MessagesAPI';
import ProductAPI from './Product/ProductAPI';
import ProjectAPI from './Project/ProjectAPI';
import ShippingAPI from './Shipping/ShippingAPI';
import SiteAPI from './Site/SiteAPI';
import UserAPI from './User/UserAPI';

function bootstrapSDK () {
    try {
        bootstrap(document.querySelector(`[${SDK_SCRIPT_ID}]`));

        return {
            Assets: AssetAPI,
            Loyalty: LoyaltyAPI,
            Messages: MessagesAPI,
            Product: ProductAPI,
            Project: ProjectAPI,
            Shipping: ShippingAPI,
            Site: SiteAPI,
            User: UserAPI,
            version: SDK_VERSION,
            hash: __webpack_hash__,
        };

    } catch (e) {
        window['console'].error(e);
        return e;
    }
}

export default bootstrapSDK();
