/**
 * Represents a preview for a templated item
 *
 * @property {TemplatePreview~page[]} pages - (Read only) Array of pages in the templated item
 */

class TemplatePreview {
    constructor (data) {
        this.pages = Object.freeze(data.pages.map(page => Object.freeze(page)));
    }
}

export default TemplatePreview;

/**
 * A preview page in a templated item.
 *
 * @typedef {Object} TemplatePreview~page
 * @property {number} pageNumber - (Read only) The page number of this page (1 based).
 * @property {string} largeUrl - (Read only) The url to the large image preview of this page.
 * @property {string} thumbnailUrl - (Read only) The url to the small image preview of this page.
 */
