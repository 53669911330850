import Country from './Country';
import State from './State';
import { intToFormattedString } from '../lib/util';

/**
 * Represents a shipment
 *
 * @property {?Country} country - (Read only) The destination country for this shipment.
 * @property {?State} state - (Read only) The desination state for this shipment.
 * @property {?string} postalCode - (Read only) The destination postal code for this shipment.
 * @property {string} description - (Read only) The label for this shipping service.
 * @property {string} vendorName - (Read only) The name of this shipping service vendor.
 * @property {number} price - (Read only) The price of shipping.
 * @property {string} displayPrice - (Read only) this.price as string, formatted with commas and two decimal places.
 * @property {?number} daysInTransit - (Read only) The number of days in transit for this shipping service. Null if not applicable (e.g. Chanhassen Pick Up).
 */

class ShippingSummary {
    constructor (data) {
        this.country = data.country ? new Country(data.country) : null;
        this.state = data.state ? new State(data.state) : null;
        this.postalCode = data.postalCode || null;
        this.description = data.shippingServiceDescription;
        this.vendorName = data.shippingVendorName;
        this.price = data.price || 0;
        this.displayPrice = intToFormattedString(this.price.toFixed(2));
        this.daysInTransit = data.daysInTransit;
    }
}

export default ShippingSummary;
