import paginationDefaults from './paginationDefaults';
import { typeCheck } from '../lib/util';

const ASCENDING = 'ASC';
const DESCENDING = 'DESC';

export default class PaginationOptions {
    constructor (paginationOptions) {
        const options = { ...paginationDefaults, ...paginationOptions };

        const pageSize = Math.abs(Number(options.pageSize));
        if (isNaN(pageSize)) {
            throw new TypeError(`Expected pageSize to be positive number, received ${options.pageSize} (${typeof options.pageSize}).`);
        }

        const page = Math.abs(Number(options.page));
        if (isNaN(page)) {
            throw new TypeError(`Expected page to be positive number, received ${options.page} (${typeof options.page}).`);
        }

        if (options.hasOwnProperty('sort')) {
            typeCheck(options.sort, Object);
            typeCheck(options.sort.fieldName, String);
            typeCheck(options.sort.ascending, Boolean);
        }

        this.size = pageSize;
        this.number = page;
        this.sort = options.sort ? [getApiSortOptions(options.sort)] : [];

        // Not supporting secondary sorts at the moment
        // options.secondarySorts && options.secondarySorts.forEach(sort => this.sort.push(getApiSort(sort)));
    }
}

// Converts pagedResponse data from API into shape used by paginationOptions
export function paginationArgumentsFromPagedResponse (paginatedResponse) {
    const [ primarySort, ...secondarySorts ] = paginatedResponse.sort;

    return {
        page: paginatedResponse.number,
        pageSize: paginatedResponse.size,
        ...primarySort && {
            sort: sortOptionsFromPagedResponse(primarySort),
        },
        ...secondarySorts.length && {
            secondarySorts: secondarySorts.map(sortOptionsFromPagedResponse)
        },
    };
}

// Converts pagedResponse.sort data from API into shape used by paginationOptions
export function sortOptionsFromPagedResponse (sort) {
    return {
        fieldName: sort.fieldName,
        ascending: sort.direction === ASCENDING,
    };
}

// Converts paginationOptions.sort data into shape used by API
function getApiSortOptions (sort) {
    return {
        fieldName: sort.fieldName,
        direction: sort.ascending ? ASCENDING : DESCENDING,
    };
}

/**
 * Object containing pagination configuration details for a {@link PaginatedList PaginatedList}.
 * @typedef {Object} PaginatedList~paginationOptions
 * @property {number} [page = 1] - The page of results to return. 1 based.
 * @property {number} [pageSize = 25] - How many results should be included per page.
 * @property {PaginatedList~paginationSort} [sort = varies by endpoint] - Sorting configuration details
 */

/**
 * Object containing sort details
 * @typedef {Object} PaginatedList~paginationSort
 * @property {string} fieldName - The property to sort the resulting objects by. Available options and default value varies by object type.
 * @property {boolean} ascending - Whether the results should be sorted in ascending (true) or descending (false) order.
 */

