import api from '../services/api';

const loyaltyService = {
    getGUID,
};

export default loyaltyService;

function getGUID () {
    const requestConfig = {
        url: '/loyalty/auth',
        method: 'GET',
    };

    return api(requestConfig);
}
