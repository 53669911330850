export default class S3FileForm {
    constructor (s3PostConfig, meta, file) {
        const META_PREFIX = 'x-amz-meta-';
        const form = new FormData();

        form.append('name', s3PostConfig.fileName);
        form.append('key', s3PostConfig.key);
        form.append('Filename', s3PostConfig.displayName);
        form.append('acl', s3PostConfig.accessControlList);
        form.append('success_action_status', '201');
        form.append('AWSAccessKeyId', s3PostConfig.awsAccessKeyId);
        form.append('policy', s3PostConfig.policy);
        form.append('x-amz-algorithm', s3PostConfig.amzAlgorithm);
        form.append('x-amz-credential', s3PostConfig.amzCredential);
        form.append('x-amz-date', s3PostConfig.amzDate);
        form.append('x-amz-signature', s3PostConfig.v4Signature);
        form.append('signature', s3PostConfig.v2Signature);
        form.append('file', file, s3PostConfig.fileName);

        for (let prop in meta) {
            if (meta.hasOwnProperty(prop)) {
                form.append(META_PREFIX + prop, meta[prop]);
            }
        }

        return form;
    }
}
