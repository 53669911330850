import fileUploadService from './fileUploadService';
import PrintFile from '../PrintFile/PrintFile';
import { typeCheck } from '../lib/util';
import { CancelToken } from '../services/api';
import getId from '../services/id';

const PRIVATE_KEY = Symbol(`FileUpload Instance ${Date.now()}${Math.random()}`);

/**
 * A file upload
 *
 * @property {number} id - (Read only) A unique id for this upload.
 * @property {string} name - (Read only) The filename being uploaded.
 * @property {Promise<PrintFile>} promise - (Read only) The promise representing the async file upload. Resolves with the uploaded {@link PrintFile PrintFile}.
 * @property {number} percent - (Read only) The upload percentage complete. This value is updated every time the associated XHR upload's progress event is fired.
 * @property {number} loaded - (Read only) The total bytes uploaded. This value is updated every time the associated XHR upload's progress event is fired.
 * @property {number} total - (Read only) The total filesize in bytes.
 * @property {?PrintFile} result - (Read only) The resulting {@link PrintFile PrintFile} record from the server after the upload has completed successfully. Null until successful upload completion.
 * @property {boolean} complete - (Read only) Whether or not this upload has completed. Once complete, the uploaded {@link PrintFile PrintFile} is available through this object's promise.then method, or this object's result property.
 * @property {?Error} error - (Read only) An upload error if one occurred, otherwise null.
 */

class FileUpload {
    static createForOrderItem (orderItem, file, onProgress) {
        return new FileUpload(file, fileUploadService.uploadToOrderItem.bind(null, orderItem, file), onProgress);
    }

    constructor (file, serviceCall, onProgress = ()=>{}) {
        let loaded = 0;
        let total = file.size;
        let complete = false;
        let error = null;
        const progressCallbacks = [];
        const tokenSource = CancelToken.source();

        const onUploadProgress = e => {
            total = e.total;
            loaded = e.loaded;
            onProgress(this);
            progressCallbacks.forEach(callback => callback(this));
        }

        const promise = serviceCall(onUploadProgress, tokenSource.token)
            .then(printFile => {
                complete = true;
                return new PrintFile(printFile);
            })
            .catch(e => {
                error = e;
                return Promise.reject(e);
            });

        Object.defineProperties(this, {
            id: {
                value: getId(),
                enumerable: true,
            },
            name: {
                value: file.name,
                enumerable: true,
            },
            promise: {
                get: () => promise,
                enumerable: true,
            },
            loaded: {
                get: () => loaded,
                enumerable: true,
            },
            total: {
                get: () => total,
                enumerable: true,
            },
            percent: {
                get: () => (loaded / total) * 100,
                enumerable: true,
            },
            complete : {
                get: () => complete,
                enumerable: true,
            },
            error: {
                get: () => error,
                enumerable: true,
            },
            _tokenSource: {
                get: () => tokenSource,
            },
            _addProgressCallback: {
                value: (progressCallback, key) => {
                    if (key !== PRIVATE_KEY) {
                        return;
                    }

                    progressCallbacks.push(progressCallback)
                },
            },
        });
    }

    /**
     * Cancels this file upload
     *
     * @returns {FileUpload} this
     */

    cancel () {
        this._tokenSource.cancel('Upload canceled by the user.');
        return this;
    }

    // Not exposed to SDK documentation. Used internally by FileUploadList to hook into the progress event
    progress (progressCallback) {
        typeCheck(progressCallback, Function);

        this._addProgressCallback(progressCallback, PRIVATE_KEY);
        return this;
    }
}

export default FileUpload;
