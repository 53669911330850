import AbstractCollaterateError from './AbstractCollaterateError';
import errorTypes from './errorTypes';

/**
 * An error that arises when an error response from the server has been received not related to user input.
 *
 * @extends AbstractCollaterateError
 * @property {string} type - "RESPONSE" - used to determine which type of Collaterate error has been thrown.
 */

class CollaterateResponseError extends AbstractCollaterateError {
    constructor (e) {
        super(e);

        this.type = errorTypes.RESPONSE;
    }
}

export default CollaterateResponseError;
