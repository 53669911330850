import FileUpload from '../FileUpload/FileUpload';
import FileUploadList from '../FileUpload/FileUploadList';
import fileUploadService from '../FileUpload/fileUploadService';
import OrderItem from '../OrderItem/OrderItem';
import PrintFile from '../PrintFile/PrintFile';
import { typeCheck } from '../lib/util';

/**
 * Asset management API interface
 * @namespace Collaterate.Assets
 */

export default class AssetAPI {
    /**
     * Upload print files for an order item
     * @param {OrderItem} item - The item to upload print files to.
     * @param {FileList} fileList - The {@link https://developer.mozilla.org/en-US/docs/Web/API/FileList FileList} of files to upload to this item.
     * @returns {FileUploadList}
     * @memberof Collaterate.Assets
     * @example
     * // Collaterate.Assets.uploadOrderItemPrintFiles returns a {@link FileUploadList FileUploadList} object, which has {@link FileUploadList#progress progress}, {@link FileUploadList#fileUploadComplete fileUploadComplete}, {@link FileUploadList#complete complete} and {@link FileUploadList#fail fail} callbacks
     * // to track the overall progress of all files being uploaded, and of the individual {@link FileUpload FileUploads} where applicable.
     *
     * Collaterate.Assets.uploadOrderItemPrintFiles(orderItem, files)
     *     .progress(onUploadListProgress)
     *     .fileUploadComplete(onFileUploadComplete)
     *     .complete(onUploadListComplete)
     *     .fail(onFileUploadFail);
     *
     * function onUploadListProgress (fileUploadList, fileUpload) {
     *     console.log(`${fileUpload.name} made progress! ${fileUpload.loaded} bytes of ${fileUpload.total} bytes, ${fileUpload.percent}% loaded.`);
     *     // --> "somefile.jpg made progress! 125 bytes of 250 bytes, 50% loaded."
     *
     *     console.log(`The list made progress! ${fileUploadList.loaded} bytes of ${fileUploadList.total} bytes, ${fileUploadList.percent}% loaded.`);
     *     // --> "The list made progress! 250 bytes of 500 bytes, 50% loaded."
     * }
     *
     * function onFileUploadComplete (fileUploadList, fileUpload, printFile) {
     *     console.log(`${fileUpload.name} done uploading? ${fileUpload.complete}!`);
     *     // --> "someFile.jpg done uploading? true!""
     *
     *     console.log(`A new printfile with id: ${printFile.id} was added to your order item.`);
     *     // --> "A new printfile with id: 12345 was added to your order item."
     * }
     *
     * function onUploadListComplete (fileUploadList) {
     *     console.log('Uploading complete. All files either successfuly uploaded or encountered an error.');
     * }
     *
     * function onFileUploadFail (fileUploadList, fileUpload) {
     *     console.log(`${fileUpload.name} encountered an error: ${fileUpload.error.message}`);
     * }
     *
     * @example
     * // In many cases, you might be interested in any change of upload state. Whether the upload progresses, completes or contains failures,
     * // you want to be notified so you can render the current upload state. For this you can use the {@link FileUploadList FileUploadList} {@link FileUploadList#change change} callback,
     * // which acts as a combination of the three other callbacks.
     *
     * Collaterate.Assets.uploadOrderItemPrintFiles(orderItem, files)
     *     .change(onUploadListChange);
     *
     * function onUploadListChange (fileUploadList, fileUpload) {
     *     console.log(`The uploads, they are a changin'...`);
     * }
     */

    static uploadOrderItemPrintFiles (item, fileList, onProgress) {
        typeCheck(item, OrderItem);
        typeCheck(fileList, FileList);
        onProgress && typeCheck(onProgress, Function);

        return new FileUploadList([...fileList].map(file => FileUpload.createForOrderItem(item, file, onProgress)));
    }

    /**
     * Deletes an order item print file
     * @param {PrintFile} file - The order item print file to delete
     * @returns {Promise} Promise that is resolved on successful operation or rejected on failure
     * @memberof Collaterate.Assets
     * @example
     * Collaterate.Assets.deleteOrderItemPrintFile(file)
     *     .then(function () {
     *         console.log('File deleted successfully!');
     *     })
     *     .catch(function (e) {
     *         console.log('Something went wrong, file not deleted.');
     *     });
     */

    static async deleteOrderItemPrintFile (file) {
        typeCheck(file, PrintFile);

        return fileUploadService.deleteOrderItemPrintFile(file.id);
    }
}
