import api, { httpService } from '../services/api';
import S3FileForm from './S3FileForm';
import { getSiteId } from '../siteId/siteId';
import { S3_SUBMISSION_PROFILE } from '../config/constants';

const fileUploadService = {
    uploadToOrderItem,
    deleteOrderItemPrintFile,
};

export default fileUploadService;

async function uploadToOrderItem (item, file, onProgress, cancelToken) {
    const reservationMsg = {
        displayName: file.name,
        submissionProfile: S3_SUBMISSION_PROFILE,
    };

    const reservationResponse = await createReservation(reservationMsg, cancelToken ? { cancelToken } : {});

    await uploadToS3FromReservation(reservationResponse, file, onProgress, cancelToken);

    return createFromStagedCartReservation({
        siteId: getSiteId(),
        orderItemId: item.id,
        stagedFileReservationName: reservationResponse.s3PostConfig.fileName,
        // projectClientKey: item.projectClientKey,
    }, cancelToken ? { cancelToken } : {});
}

function deleteOrderItemPrintFile (fileId, config) {
    const requestConfig = {
        url: `/cart/orderItemConsumerPrintFiles/${fileId}`,
        method: 'DELETE',
    };

    return api(Object.assign({}, requestConfig, config));
}

function createReservation (data, config) {
    const requestConfig = {
        url: `/cart/stagedFileReservations/create`,
        method: 'POST',
        data,
    };

    return api(Object.assign({}, requestConfig, config));
}

function uploadToS3FromReservation (response, file, onProgress, cancelToken) {
    const requestConfig = {
        url: response.s3PostConfig.bucketUrl,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: new S3FileForm(response.s3PostConfig, response.meta, file),
        onUploadProgress: onProgress,
    };

    if (cancelToken) {
        requestConfig.cancelToken = cancelToken;
    }

    return httpService(requestConfig);
}

function createFromStagedCartReservation (data, config) {
    const requestConfig = {
        url: `/cart/orderItemConsumerPrintFiles/createFromStagedFileReservation`,
        method: 'POST',
        data,
    };

    return api(Object.assign({}, requestConfig, config));
}

// function createFromStagedSalesEstimateReservation (data, config) {
//     const requestConfig = {
//         url: `/salesEstimate/orderItemConsumerPrintFiles/createFromStagedFileReservation`,
//         method: 'POST',
//         data,
//     };

//     return api(Object.assign({}, requestConfig, config));
// }
